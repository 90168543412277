import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import * as yup from "yup";
import { atualizarPrecoSchema } from "../../schema/atualizarPrecoSchema";

export interface AtualizaPrecoProps {
    atualizar: (preco: string) => void;
    aberto: boolean;
    fechar: () => void;
}

export const ModalAtualizaPreco = ({
    atualizar,
    fechar,
    aberto
}: AtualizaPrecoProps) => {
    const [preco, setPreco] = useState("");
    const [erro, setErro] = useState<string | null>(null);

    const validatePreco = async (value: string) => {
        try {
            await atualizarPrecoSchema.validate({ preco: value });
            setErro(null);
            return true;
        } catch (validationError) {
            setErro((validationError as yup.ValidationError).message);
            return false;
        }
    };

    const handleFechar = () => {
        setPreco("");
        setErro(null);
        fechar();
    };

    const handleAtualizar = async () => {
        const isValid = await validatePreco(preco);
        if (!isValid) return;

        atualizar(preco);
        handleFechar();
    };

    return (
        <Dialog
            open={aberto}
            onClose={handleFechar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Atualizar preço</DialogTitle>
            <DialogContent sx={{ minWidth: "450px" }}>
                <DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth sx={{ mt: 3 }} error={!!erro}>
                        <InputLabel htmlFor="preco">Preço</InputLabel>
                        <OutlinedInput
                            id="preco"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Preço"
                            value={preco}
                            onChange={(e) => {
                                setPreco(e.target.value);
                                setErro(null);
                            }}
                            onBlur={() => validatePreco(preco)}
                        />
                        {erro && <FormHelperText>{erro}</FormHelperText>}
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleFechar} color="primary">
                    Cancelar
                </Button>
                <Button 
                    variant='contained' 
                    onClick={handleAtualizar} 
                    color="primary" 
                    disabled={!!erro || !preco}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
