import { Add, Delete } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useLetras } from "../../../core/hooks/useLetras";
import { DiaSemana } from "../../../core/enum/DiaSemana";
import { ICategoria } from "../../interfaces/ICategoria";
import { IPeriodoExibicaoCategoria } from "../../interfaces/IPeriodoExibicaoCategoria";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";

interface PeriodoExibicaoCategoriaProps {
    periodosExibicao?: IPeriodoExibicaoCategoria[];
    adicionar: (periodo: IPeriodoExibicaoCategoria) => void;
    excluir: (diaSemana: DiaSemana) => void;
}

export const PeriodoExibicaoCategoria = ({ periodosExibicao, adicionar, excluir }: PeriodoExibicaoCategoriaProps) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setError(null);
        setHorario1Inicio(null);
        setHorario1Fim(null);
        setHorario2Inicio(null);
        setHorario2Fim(null);
        setHorario3Inicio(null);
        setHorario3Fim(null);
        setDiaSemana(undefined);
        setOpen(false)
    }
    const { titulo } = useLetras();

    const [diaSemana, setDiaSemana] = useState<string | undefined>(undefined);
    const [horario1Inicio, setHorario1Inicio] = useState<Moment | null>(null);
    const [horario1Fim, setHorario1Fim] = useState<Moment | null>(null);
    const [horario2Inicio, setHorario2Inicio] = useState<Moment | null>(null);
    const [horario2Fim, setHorario2Fim] = useState<Moment | null>(null);
    const [horario3Inicio, setHorario3Inicio] = useState<Moment | null>(null);
    const [horario3Fim, setHorario3Fim] = useState<Moment | null>(null);
    const [error, setError] = useState<string | null>(null);

    const diasSemanaOptions = Object.keys(DiaSemana).filter((diaSemana) => {
        return !periodosExibicao?.find((periodo) => periodo.diaSemana === diaSemana);
    }).map((diaSemana) => {
        return (
            <MenuItem key={diaSemana} value={diaSemana}>
                {titulo(diaSemana)}
            </MenuItem>
        );
    });

    const validateHorarios = () => {
        if ((horario1Inicio && horario1Fim) && horario1Inicio.isAfter(horario1Fim)) {
            setError("O primeiro horário de início deve ser antes do horário de término.");
            return false;
        }
        if (horario2Inicio && horario2Fim && horario2Inicio.isAfter(horario2Fim)) {
            setError("O segundo horário de início deve ser antes do horário de término.");
            return false;
        }
        if (horario3Inicio && horario3Fim && horario3Inicio.isAfter(horario3Fim)) {
            setError("O terceiro horário de início deve ser antes do horário de término.");
            return false;
        }
        if (checkConflict(horario1Inicio, horario1Fim, horario2Inicio, horario2Fim) ||
            checkConflict(horario1Inicio, horario1Fim, horario3Inicio, horario3Fim) ||
            checkConflict(horario2Inicio, horario2Fim, horario3Inicio, horario3Fim)) {
            setError("Os horários não podem conflitar entre si.");
            return false;
        }
        setError(null);
        return true;
    };

    const checkConflict = (start1: Moment | null, end1: Moment | null, start2: Moment | null, end2: Moment | null) => {
        if (start1 && end1 && start2 && end2) {
            return start1.isBefore(end2) && start2.isBefore(end1);
        }
        return false;
    };

    const snackbar = useSnackbar();

    const handleSalvar = async () => {
        const valido = validateHorarios();

        const diaSemanaDuplicado = periodosExibicao?.find(periodo => periodo.diaSemana === diaSemana);
        if(diaSemanaDuplicado) {
            return snackbar("Dia da semana já configurado", { severity: "error" })
        }

        if (valido) {
            adicionar({
                diaSemana: diaSemana as DiaSemana,
                horarios: [
                    { inicio: horario1Inicio?.format("HH:mm") || "", fim: horario1Fim?.format("HH:mm") || "" },
                    { inicio: horario2Inicio?.format("HH:mm") || "", fim: horario2Fim?.format("HH:mm") || "" },
                    { inicio: horario3Inicio?.format("HH:mm") || "", fim: horario3Fim?.format("HH:mm") || "" }
                ].filter(horario => horario.inicio && horario.fim)
            });
            handleClose();
        }
    };

    const handleExcluir = (diaSemana: DiaSemana) => {
        excluir(diaSemana);
        handleClose();
    }

    return (
        <>
            <Paper variant="outlined" sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography variant="h6">Horários</Typography>
                    <Button endIcon={<Add />} onClick={handleOpen}>
                        Adicionar
                    </Button>
                </Box>
                {!periodosExibicao?.length ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "2rem" }}>
                        <Typography>Nenhum horário de funcionamento configurado</Typography>
                    </Box>
                ) : (
                    <Box>
                        {periodosExibicao?.map(periodo => {
                            return (
                                <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        {titulo(periodo.diaSemana)}
                                        <Button onClick={() => excluir(periodo.diaSemana)} endIcon={<Delete />}>Excluir</Button>
                                    </Box>
                                    {periodo.horarios?.length ? periodo.horarios.map((horario, index) => {
                                        return (
                                            <Paper variant="outlined" sx={{
                                                padding: "1rem",
                                                justifyContent: "space-between",
                                                display: "flex",
                                                marginTop: "1rem"
                                            }}>
                                                <Typography>{index + 1}º Horário</Typography>
                                                <Box sx={{
                                                    justifyContent: "space-between",
                                                    display: "flex",
                                                }}>
                                                    <Typography>{horario.inicio}</Typography>
                                                    <Typography sx={{ mr: "0.5rem", ml: "0.5rem"}}>-</Typography>
                                                    <Typography>{horario.fim}</Typography>
                                                </Box>
                                            </Paper>
                                        );
                                    }) : (
                                        <Paper variant="outlined" sx={{
                                            padding: "1rem",
                                            justifyContent: "space-between",
                                            display: "flex",
                                            marginTop: "1rem"
                                        }}>
                                            <Typography>Nenhum horário configurado</Typography>
                                        </Paper>
                                    )}
                                </Paper>
                            )
                        })}
                    </Box>
                )}
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-horario"
                aria-describedby="dialog-horario-descricao"
            >
                <DialogTitle id="dialog-horario">
                    Período de exibição
                </DialogTitle>
                <DialogContent sx={{ minWidth: "400px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Alert severity="info">
                        <AlertTitle>A definição de horários impacta na visualização das categorias</AlertTitle>
                        Apenas as categorias que se enquadram no período configurado irão aparecer para os usuários
                    </Alert>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <FormControl sx={{ width: "100%", m: "0.5rem auto", mt: "2rem" }}>
                        <InputLabel
                            id="dia-semana-label"
                        >
                            Dia da semana*
                        </InputLabel>
                        <Select
                            labelId="dia-semana-label"
                            id="diaSemana"
                            placeholder="Selecione um dia da semana"
                            label="Dia da semana*"
                            defaultValue={undefined}
                            value={diaSemana}
                            onChange={(e) => setDiaSemana(e.target.value)}
                        >
                            {diasSemanaOptions}
                        </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>1º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 1º horário"
                                    value={horario1Inicio}
                                    onChange={(newValue) => setHorario1Inicio(newValue)}
                                    disabled={!diaSemana}
                                    ampm={false}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 1º horário"
                                    value={horario1Fim}
                                    onChange={(newValue) => setHorario1Fim(newValue)}
                                    disabled={!horario1Inicio}
                                    ampm={false}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>2º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 2º horário"
                                    value={horario2Inicio}
                                    onChange={(newValue) => setHorario2Inicio(newValue)}
                                    disabled={!horario1Fim}
                                    ampm={false}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 2º horário"
                                    value={horario2Fim}
                                    onChange={(newValue) => setHorario2Fim(newValue)}
                                    disabled={!horario2Inicio}
                                    ampm={false}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>3º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 3º horário"
                                    value={horario3Inicio}
                                    onChange={(newValue) => setHorario3Inicio(newValue)}
                                    disabled={!horario2Fim}
                                    ampm={false}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 3º horário"
                                    value={horario3Fim}
                                    onChange={(newValue) => setHorario3Fim(newValue)}
                                    disabled={!horario3Inicio}
                                    ampm={false}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCELAR</Button>
                    <Button onClick={handleSalvar} variant="contained" autoFocus>
                        SALVAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}