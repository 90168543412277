import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { getToken, useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { useFetch } from "../../core/hooks/useFetch";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IErro } from "../../core/interfaces/IError";
import { IEntregador } from "../interfaces/IEntregador";

export function useEntregadores () {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiEntregadores = useMemo(() => ApiFactory.getApi("MS_ENTREGADORES"), []);

    const salvar = useCallback(async (entregador: IEntregador) => {
        if(loading) {
            return false;
        }
        try {
            setLoading(true);
            const { data } = await apiEntregadores.post('/', {
                ...entregador
            });
            if(data) {
                return true;
            }
            return false;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        } finally {
            setLoading(false);
        }
    }, [])  

    const excluir = useCallback(async (uuid: string) => {
        try {
            setLoading(true);
            const { data } = await apiEntregadores.delete(`/${uuid}`);
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return null;
        } finally {
            setLoading(false);
        }
    }, [])

    const buscar = useCallback(async (uuid: string) => {
        try {
            setLoading(true);
            const { data } = await apiEntregadores.get(`/${uuid}`);
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return null;
        } finally {
            setLoading(false);
        }
    }, [])  

    const listar = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await apiEntregadores.get(`/`);
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return null;
        } finally {
            setLoading(false);
        }
    }, [])  

    return { 
        salvar,
        excluir,
        buscar,
        loading,
        listar
    }
}