import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IMarketplace } from "../interfaces/IMarketplace";
import { AxiosError } from "axios";
import { IImagemMarketplace } from "../interfaces/IImagemMarketplace";
import { ReferenciaImagemMarketplace } from "../enum/ReferenciaImagemMarketplace";
import { ICategoria } from "../../produtos/interfaces/ICategoria";
import { IMarketplaceCategoria } from "../../produtos/interfaces/IMarketplaceCategoria";
import { IProduto } from "../../produtos/interfaces/IProduto";

export function useMarketplaces () {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiMarketplaces = useMemo(() => ApiFactory.getApi("MS_MARKETPLACES"), []);
    
    const salvar = async (marketplace: IMarketplace): Promise<null | IMarketplace> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/`, marketplace);
            if(!data) {
                showSnackbar("Ocorreu um erro ao salvar o marketplace", { severity: "error" });
                return null;
            }

            return data.marketplace;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao salvar o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const upload = async (uuid: string, arquivo: File, referencia: ReferenciaImagemMarketplace) => {
        if(loading) {
            return;
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('arquivo', arquivo);

        try { 
            const { data } = await apiMarketplaces.post(`/${uuid}/imagens/upload/${referencia}`, formData);
            if(!data) {
                showSnackbar("Ocorreu um erro ao realizar o upload", { severity: "error" });
                return null;
            }

            return data.imagem;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao realizar o upload", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listar = async () => {
        const { data } = await apiMarketplaces.get(`/`);
        return data;
    }

    return {
        salvar,
        loading,
        upload,
        listar
    }
}