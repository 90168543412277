import styled from "@emotion/styled";
import { Box, Card, CardActionArea, css } from "@mui/material";

export const LoadingContainer = styled(Box)`
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
`

export const SemEntregadorContainer = styled(Box)`
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    display: flex;
`

export const CardContainer = styled(CardActionArea) <{ selecionado?: boolean }>`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    padding: 1rem;
    transition: 0.3s;

    ${props =>
        props.selecionado &&
        css`
            background-color: ${props.theme.palette.primary.main}22;
            color: ${props.theme.palette.primary.main};
            border: 1px solid ${props.theme.palette.primary.main};
    `}

    &:hover {
        ${props =>
        css`
            background-color: ${props.theme.palette.primary.main}22;
            color: ${props.theme.palette.primary.main};
            border: 1px solid ${props.theme.palette.primary.main};
        `}
    }
`