import { Moment } from "moment";
import { useState } from "react";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useIFood } from "../../../hooks/useIFood";
import { useDataHora } from "../../../../core/hooks/useDataHora";
import { IHorariosFuncionamentoIFood, IHorarioFuncionamentoIFood } from "../../../interfaces/IHorariosFuncionamentoIFood";
import { v4 } from "uuid";

interface ModalCriarHorarioFuncionamentoIFoodProps {
    marketplaceUuid: string;
    open: boolean;
    close: () => void;
    horarios: IHorariosFuncionamentoIFood;
}

const diasDaSemana = [
    { label: "Segunda-feira", value: "MONDAY" },
    { label: "Terça-feira", value: "TUESDAY" },
    { label: "Quarta-feira", value: "WEDNESDAY" },
    { label: "Quinta-feira", value: "THURSDAY" },
    { label: "Sexta-feira", value: "FRIDAY" },
    { label: "Sábado", value: "SATURDAY" },
    { label: "Domingo", value: "SUNDAY" },
];

export const ModalCriarHorarioFuncionamentoIFood = ({ marketplaceUuid, open, close, horarios }: ModalCriarHorarioFuncionamentoIFoodProps) => {
    const [dia, setDia] = useState<string>("MONDAY");
    const [inicio, setInicio] = useState<Moment | null>(null);
    const [fim, setFim] = useState<Moment | null>(null);
    const notificar = useSnackbar();

    const { dataEhDepois, calcularDuracao } = useDataHora();
    const { salvarHorarios, loading } = useIFood();

    const existeConflito = (novoHorario: IHorarioFuncionamentoIFood): boolean => {
        return horarios.shifts?.some((horario) => {
            if (horario.dayOfWeek !== novoHorario.dayOfWeek) return false;
            const [startHour, startMinute] = horario.start.split(":").map(Number);
            const horarioInicio = startHour * 60 + startMinute;
            const horarioFim = horarioInicio + horario.duration;
    
            const [novoStartHour, novoStartMinute] = novoHorario.start.split(":").map(Number);
            const novoInicio = novoStartHour * 60 + novoStartMinute;
            const novoFim = novoInicio + novoHorario.duration;
    
            const conflito = (novoInicio < horarioFim && novoFim > horarioInicio);    
            return conflito;
        }) ?? false;
    };

    const handleCriarHorario = async () => {
        if (loading) return;

        if (!inicio || !fim) {
            notificar("Os horários de início e fim são obrigatórios.", { severity: "error" });
            return;
        }

        if (!inicio.isValid() || !fim.isValid()) {
            notificar("O horário informado é inválido.", { severity: "error" });
            return;
        }

        if (!dataEhDepois(inicio, fim)) {
            notificar("O horário de início não pode ser posterior ao horário de término.", { severity: "error" });
            return;
        }

        if (!marketplaceUuid) return;

        const inicioFormatado = `${inicio.format("HH:mm")}:00`;
        const duracao = calcularDuracao(inicio, fim);

        const novoHorario: IHorarioFuncionamentoIFood = {
            dayOfWeek: dia as IHorarioFuncionamentoIFood["dayOfWeek"],
            start: inicioFormatado,
            duration: duracao,
            id: v4()
        };

        if (existeConflito(novoHorario)) {
            notificar("O horário informado conflita com um horário já cadastrado.", { severity: "error" });
            return;
        }

        const novosHorarios = { shifts: [...(horarios.shifts || []), novoHorario] };
        const resultado = await salvarHorarios(marketplaceUuid, novosHorarios);
        if (resultado) {
            notificar("Horário salvo com sucesso!", { severity: "success" });
            handleClose();
        }
    };

    const handleClose = () => {
        setInicio(null);
        setFim(null);
        setDia("MONDAY");
        close();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle>Criar horário</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", mb: "1rem" }}>
                    <FormControl sx={{ width: "100%" }}>
                        <Select value={dia} onChange={(e) => setDia(e.target.value)}>
                            {diasDaSemana.map((dia) => (
                                <MenuItem key={dia.value} value={dia.value}>
                                    {dia.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: "flex", mb: "1rem" }}>
                    <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                        <TimePicker label="Início" value={inicio} onChange={setInicio} ampm={false} />
                    </FormControl>
                    <FormControl sx={{ width: "50%", display: "flex" }}>
                        <TimePicker label="Fim" value={fim} onChange={setFim} ampm={false} />
                    </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: "2rem" }}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                    {loading ? (
                        <Button fullWidth variant="outlined" sx={{ height: "50px" }}>
                            <CircularProgress size={30} />
                        </Button>
                    ) : (
                        <Button fullWidth variant="contained" onClick={handleCriarHorario} sx={{ height: "50px" }}>
                            Criar
                        </Button>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};