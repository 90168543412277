import { Alert, Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { IComplemento } from "../../interfaces/IComplemento";
import { CadastrarComplementoContainer } from "../CadastrarComplementoContainer";
import { ButtonsContainerForm, ComplementosContainer as ComplementosContainerCard, FiltroContainer } from "./styles";
import { Add, ArrowDropDown, CopyAll, Edit } from "@mui/icons-material";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { ModalCopiarComplemento } from "./containers/ModalCopiarComplemento";

interface ComplementosContainerProps { }

export const ComplementosContainer = ({ }: ComplementosContainerProps) => {
    const [tab, setTab] = useState<"tabela" | "formulario">("tabela");
    const [complementoEdicao, setComplementoEdicao] = useState<undefined | IComplemento>();
    const [copiarComplementoAberto, setCopiarComplementoAberto] = useState<boolean>(false);
    const { complementos, salvarComplemento, excluirComplemento, habilitarComplementos, handleVoltar } = useCadastrarEditarProdutoContext();
    const { modeloTela } = useTamanhoTela();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const abrirEdicaoComplemento = (complemento: IComplemento) => {
        setComplementoEdicao(complemento);
        setTab("formulario");
    }

    const abrirCopiarComplemento = () => {
        setCopiarComplementoAberto(true);
        handleClose();
    }

    const fecharCopiarComplemento = () => {
        setCopiarComplementoAberto(false);
    }

    const voltar = () => {
        setComplementoEdicao(undefined);
        setTab("tabela");
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ComplementosContainerCard>
            {tab == "tabela" && (
                <TableContainer variant='outlined' component={Paper} sx={{
                    width: "100%",
                    maxHeight: 450,
                    height: "100%",
                    minHeight: 350
                }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="h6">
                            Complementos
                        </Typography>
                        <FiltroContainer>
                            <Box>
                                <Button
                                    onClick={handleClick}
                                    endIcon={<ArrowDropDown />}
                                    variant="contained"
                                >
                                    Ações
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <Tooltip title={'Copiar complemento de outro produto'}>
                                        <MenuItem onClick={abrirCopiarComplemento}>
                                            <ListItemIcon>
                                                <CopyAll fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                Copiar complemento
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                </Menu>
                            </Box>
                            <Tooltip title='Crie um novo complemento'>
                                <IconButton disabled={!habilitarComplementos} color="primary" onClick={() => setTab("formulario")}>
                                    <Add />
                                </IconButton>
                            </Tooltip>

                        </FiltroContainer>
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Número de Itens</TableCell>
                                <TableCell>Preenchimento obrigatório</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {complementos?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4}>Nenhum complemento registrado</TableCell>
                                </TableRow>
                            ) : (
                                complementos?.map((complemento) => (
                                    <TableRow key={complemento.uuid}>
                                        <TableCell component="th" scope="row">
                                            {complemento.descricao}
                                        </TableCell>
                                        <TableCell>{complemento.itens?.length ?? 0}</TableCell>
                                        <TableCell>{complemento.obrigatorio ? "Sim" : "Não"}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => abrirEdicaoComplemento(complemento)} aria-label="delete" size="small">
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {tab == "formulario" && (
                <CadastrarComplementoContainer
                    complemento={complementoEdicao}
                    excluir={excluirComplemento}
                    salvar={salvarComplemento}
                    voltar={voltar}
                />
            )}
            {tab == "tabela" && (
                <ButtonsContainerForm>
                    <Button size="medium" variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                </ButtonsContainerForm>
            )}
            <ModalCopiarComplemento 
                aberto={copiarComplementoAberto}
                copiar={salvarComplemento}
                fechar={fecharCopiarComplemento}
            />
        </ComplementosContainerCard>
    );
}