import { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { IMarketplace } from "../../../interfaces/IMarketplace";
import { ComportamentoCard, ComportamentosContainer, NenhumComportamentoContainer, TituloComportamentoContainer } from "./styles";
import { IComportamentoWhatsApp } from "../../../interfaces/IComportamentoWhatsAppMarketplace";
import { TipoComportamentoWhatsApp, TipoComportamentoWhatsAppDescricao } from "../../../enum/TipoComportamentoWhatsappMarketplace";
import { IIntegracaoWhatsApp } from "../../../interfaces/IIntegracaoMarketplace";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../../enum/TipoMarketplace";
import { useNavigate } from "react-router-dom";
import { useMarketplaces } from "../../../hooks/useMarketplaces";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";

interface ConfigurarComportamentosWhatsAppProps {
    marketplaces: IMarketplace[];
    marketplace: IMarketplace;
    refresh: () => void;
}

const FormAtendimentoRestaurante = ({ marketplaces, onChange }: { marketplaces: IMarketplace[], onChange: (value: any) => void }) => {
    const marketplacesValdidos = marketplaces?.filter(marketplace => {
        return marketplace.tipo == TipoMarketplace.CARDAPIO_DIGITAL_DELIVERY_RETIRADA
    })

    return (
        <>
            <FormControl fullWidth sx={{ mt: "1.5rem" }}>
                <InputLabel>Marketplaces</InputLabel>
                <Select
                    label={"Marketplaces"}
                    fullWidth
                    onChange={(e) => onChange(e.target.value)}
                >
                    {marketplacesValdidos.length > 0 ? (
                        marketplacesValdidos.map((mp) => (
                            <MenuItem key={mp.uuid} value={mp.uuid}>
                                {mp.nome}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Nenhum marketplace de cardápio disponível</MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
};

const ContainerAtendimentoRestaurante = ({ comportamento, marketplaces }: { comportamento: IComportamentoWhatsApp, marketplaces: IMarketplace[] }) => {
    const navigate = useNavigate();
    const marketplace = marketplaces?.find(marketplace => marketplace.uuid == comportamento?.metadados?.marketplaceUuidCardapioDigital);

    return (
        <Box>
            <Typography variant="body2" fontWeight={"400"}>
                O atendente encaminhará o cliente para fazer os pedidos em:
            </Typography>
            {marketplace ? (
                <Typography
                    variant="body2"
                    color={TipoMarketplaceCor[marketplace?.tipo as TipoMarketplace]}
                    sx={{ cursor: "pointer", mt: '1rem', fontWeight: "bold" }}
                    onClick={() => navigate(`/marketplace/${marketplace?.uuid}`)}
                >
                    {marketplace?.nome} ({TipoMarketplaceTitulo[marketplace.tipo]})
                </Typography>
            ) : (
                <Typography
                    variant="body2"
                    color={"error"}
                    sx={{ mt: '1rem' }}
                >
                    O marketplace foi excluído
                </Typography>
            )}

        </Box>
    );
};

export const ConfigurarComportamentosWhatsApp = ({ marketplaces, marketplace, refresh }: ConfigurarComportamentosWhatsAppProps) => {
    const [open, setOpen] = useState(false);
    const { salvar, loading } = useMarketplaces();
    const [novoComportamento, setNovoComportamento] = useState<IComportamentoWhatsApp>({
        prioridade: 1,
        tipo: TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE,
        metadados: {},
    });

    const integracao = marketplace?.configuracao?.integracao as IIntegracaoWhatsApp;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (field: keyof IComportamentoWhatsApp, value: any) => {
        setNovoComportamento(prev => ({ ...prev, [field]: value }));
    };

    const notificar = useSnackbar();

    const handleExcluirComportamento = () => {
        if(loading) return;

        salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    ...integracao,
                    comportamentos: []
                }
            }
        });
        setNovoComportamento({
            prioridade: 1,
            tipo: TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE,
            metadados: {},
        })
        handleClose();
        refresh();
    };

    const handleCadastrarComportamento = () => {
        if (loading) return;

        if (!novoComportamento.tipo) {
            return notificar("Tipo de comportamento inválido", { severity: 'error' })
        }

        if (!Number.isInteger(novoComportamento.prioridade) || novoComportamento?.prioridade < 1) {
            return notificar("Prioridade inválida", { severity: 'error' })
        }

        if (novoComportamento.tipo == TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE) {
            if (!novoComportamento.metadados?.marketplaceUuidCardapioDigital) {
                return notificar("Marketplace inválido", { severity: 'error' })
            }
        }

        const novosComportamentos = [];

        if (integracao.comportamentos?.length) {
            novosComportamentos.push(...integracao.comportamentos)
        }

        if (novoComportamento) {
            novosComportamentos.push(novoComportamento);
        }

        salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    ...integracao,
                    comportamentos: novosComportamentos
                }
            }
        });
        setNovoComportamento({
            prioridade: 1,
            tipo: TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE,
            metadados: {},
        })
        handleClose();
        refresh();
    };

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Typography variant="h6">Comportamentos</Typography>
            {integracao.comportamentos?.length ? (
                <ComportamentosContainer>
                    {integracao?.comportamentos?.map(comportamento => {
                        return (
                            <ComportamentoCard variant="outlined">
                                <TituloComportamentoContainer>
                                    <Typography>
                                        {comportamento.prioridade}. {TipoComportamentoWhatsAppDescricao[comportamento.tipo]}
                                    </Typography>
                                    {loading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                    <IconButton color="error" onClick={handleExcluirComportamento}>
                                    <Delete />
                                </IconButton>
                                    )}
                                </TituloComportamentoContainer>
                                <Divider />
                                {comportamento.tipo == TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE && (
                                    <ContainerAtendimentoRestaurante marketplaces={marketplaces} comportamento={comportamento} />
                                )}
                            </ComportamentoCard>
                        );
                    })}
                </ComportamentosContainer>
            ) : (
                <NenhumComportamentoContainer>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                        Nenhum comportamento cadastrado. Adicione um novo comportamento para começar.
                    </Typography>
                    <Button onClick={handleOpen}>
                        Criar Comportamento
                    </Button>
                </NenhumComportamentoContainer>
            )}

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Novo Comportamento</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Prioridade"
                        type="number"
                        fullWidth
                        margin="dense"
                        value={novoComportamento.prioridade}
                        onChange={(e) => handleChange("prioridade", Number(e.target.value))}
                    />
                    <FormControl fullWidth sx={{ mt: "1.5rem" }}>
                        <InputLabel>Tipo</InputLabel>
                        <Select
                            label="Tipo"
                            fullWidth
                            value={novoComportamento.tipo}
                            onChange={(e) => handleChange("tipo", e.target.value as TipoComportamentoWhatsApp)}
                        >
                            {Object.values(TipoComportamentoWhatsApp).map((tipo) => (
                                <MenuItem key={tipo as string} value={tipo as string}>
                                    {TipoComportamentoWhatsAppDescricao[tipo as keyof typeof TipoComportamentoWhatsAppDescricao]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {novoComportamento.tipo === TipoComportamentoWhatsApp.ATENDIMENTO_RESTAURANTE && (
                        <FormAtendimentoRestaurante marketplaces={marketplaces} onChange={(value) => handleChange("metadados", { marketplaceUuidCardapioDigital: value })} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Cancelar</Button>
                    <Button
                        onClick={handleCadastrarComportamento}
                        variant={loading ? 'outlined' : "contained"}
                    >
                        {
                            loading
                                ? <CircularProgress size={20} />
                                : 'Cadastrar'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}