import { IMarketplace } from "../../../../interfaces/IMarketplace"
import { Box, Breadcrumbs, Button, Card, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, Link, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DadoCardContainer, DadoContainer, PageContainer } from "../../styles";
import { IntegracaoContainer, LoadingContainer, NenhumaInterrupcaoContainer } from "./styles";
import { TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../../../enum/TipoMarketplace";
import { useNavigate } from "react-router-dom";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { Add, Delete, Edit, Remove } from "@mui/icons-material";
import { IIntegracaoIFood } from "../../../../interfaces/IIntegracaoMarketplace";
import { VinculoIFoodPaper } from "../../../../components/ifood/VinculoIFoodPaper";
import { VincularMerchantIFoodV2 } from "../../../../components/ifood/VincularMerchantIFoodV2";
import { useEffect, useState } from "react";
import { useIFood } from "../../../../hooks/useIFood";
import { TimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { IStatusEstabelecimentoIFood } from "../../../../interfaces/IStatusEstabelecimentoIFood";
import { StatusEstabelecimentoIFood, StatusEstabelecimentoIFoodCor, StatusEstabelecimentoIFoodDescricao } from "../../../../enum/StatusEstabelecimentoIFood";
import { IDetalhesEstabelecimentoIFood } from "../../../../interfaces/IDetalhesEstabelecimentoIFood";
import { IInterrupcaoIFood } from "../../../../interfaces/IInterrupcaoIFood";
import { CardInterrupcaoIFood } from "../../../../components/ifood/CardInterrupcaoIFood";
import { ModalCriarInterrupcaoIFood } from "../../../../components/ifood/ModalCriarInterrupcaoIFood";
import { IHorarioFuncionamentoIFood, IHorariosFuncionamentoIFood } from "../../../../interfaces/IHorariosFuncionamentoIFood";
import { CardHorariosFuncionamentoIFood } from "../../../../components/ifood/CardHorarioFuncionamentoIFood";
import { ModalCriarHorarioFuncionamentoIFood } from "../../../../components/ifood/ModalCriarHorarioFuncionamentoIFood";
interface CardapioOnlineContainerProps {
    marketplace: IMarketplace
    handleRefresh: () => void,
    loadingFetch: boolean
}

export const IFoodContainer = ({ marketplace, handleRefresh, loadingFetch }: CardapioOnlineContainerProps) => {
    const [detalhes, setDetalhes] = useState<IDetalhesEstabelecimentoIFood | undefined>();
    const [status, setStatus] = useState<IStatusEstabelecimentoIFood[]>([]);
    const [interrupcoes, setInterrupcoes] = useState<IInterrupcaoIFood[]>([]);
    const [horarios, setHorarios] = useState<undefined | IHorariosFuncionamentoIFood>();

    const [modalInterrupcaoOpen, setModalInterrupcaoOpen] = useState<boolean>(false);
    const [modalHorariosOpen, setModalHorariosOpen] = useState<boolean>(false);

    const handleModalInterrupcaoOpen = () => {
        setModalInterrupcaoOpen(true);
    }

    const handleModalInterrupcaoClose = () => {
        setModalInterrupcaoOpen(false);
        handleBuscarDetalhes();
    }

    const handleModalHorariosOpen = () => {
        setModalHorariosOpen(true);
    }

    const handleModalHorariosClose = () => {
        setModalHorariosOpen(false);
        handleBuscarDetalhes();
    }

    const navigate = useNavigate();
    const { real } = useMoeda();
    const { buscarDetalhes, consultarStatus, listarInterrupcoes, listarHorarios, loading } = useIFood();
    const { dataHoraBr } = useDataHora();

    const integracao = marketplace?.configuracao?.integracao as IIntegracaoIFood;

    const handleBuscarDetalhes = async () => {
        if (!integracao.catalogId) return;
        if (!marketplace?.uuid) return;

        const detalhes = await buscarDetalhes(marketplace?.uuid);
        setDetalhes(detalhes);

        const status = await consultarStatus(marketplace?.uuid);
        if (status) setStatus(status);

        const interrupcoes = await listarInterrupcoes(marketplace?.uuid);
        setInterrupcoes(interrupcoes);

        const horarios = await listarHorarios(marketplace?.uuid);
        setHorarios(horarios);
    }

    useEffect(() => {
        handleBuscarDetalhes();
    }, [integracao])

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplace && (
                    <Typography color="text.primary">{marketplace.nome}</Typography>
                )}
            </Breadcrumbs>
            {!integracao?.catalogId ? (
                <IntegracaoContainer>
                    <VincularMerchantIFoodV2
                        marketplace={marketplace}
                        handleRefresh={handleRefresh}
                        loadingFetch={loadingFetch}
                    />
                </IntegracaoContainer>
            ) : (
                <Grid container spacing={2} sx={{ p: 2, mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        <VinculoIFoodPaper refresh={handleRefresh} marketplace={marketplace} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography variant="h6">Dados</Typography>
                            </Box>
                            {loading ? (
                                <LoadingContainer>
                                    <CircularProgress size={40} />
                                </LoadingContainer>
                            ) : (
                                <>
                                    <DadoContainer>
                                        <Typography>Nome</Typography>
                                        <Typography>{detalhes?.name}</Typography>
                                    </DadoContainer>
                                    <DadoContainer>
                                        <Typography>Nome corporativo</Typography>
                                        <Typography>{detalhes?.corporateName}</Typography>
                                    </DadoContainer>
                                    <DadoContainer>
                                        <Typography>Ticket médio</Typography>
                                        <Typography>{detalhes?.averageTicket ? real(detalhes?.averageTicket) : '-'}</Typography>
                                    </DadoContainer>
                                    <DadoContainer>
                                        <Typography>Status</Typography>
                                        <Typography>{detalhes?.status == 'AVAILABLE' ? 'Disponível' : 'Indisponível'}</Typography>
                                    </DadoContainer>
                                    <DadoContainer>
                                        <Typography>Criado às</Typography>
                                        <Typography>{dataHoraBr(detalhes?.createdAt)}</Typography>
                                    </DadoContainer>
                                </>
                            )}

                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography variant="h6">Status</Typography>
                            </Box>
                            {loading
                                ? (
                                    <LoadingContainer>
                                        <CircularProgress size={40} />
                                    </LoadingContainer>
                                )
                                : status?.map((status: IStatusEstabelecimentoIFood) => {
                                    return (
                                        <Card variant="outlined" sx={{ mt: '1rem' }}>
                                            <DadoCardContainer>
                                                <Typography>Operação</Typography>
                                                <Typography>{status?.operation}</Typography>
                                            </DadoCardContainer>
                                            <DadoCardContainer>
                                                <Typography>{status?.message?.title}</Typography>
                                                <Typography>
                                                    <Chip
                                                        label={StatusEstabelecimentoIFoodDescricao[status?.state]}
                                                        sx={{
                                                            color: 'white',
                                                            background: StatusEstabelecimentoIFoodCor[status?.state]
                                                        }}
                                                    />
                                                </Typography>
                                            </DadoCardContainer>
                                            {status?.message?.subtitle && (
                                                <DadoCardContainer>
                                                    <Typography>{status?.message?.subtitle}</Typography>
                                                </DadoCardContainer>
                                            )}
                                        </Card>
                                    )
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: "1rem" }}>
                                <Typography variant="h6">Interrupções</Typography>
                                <Button onClick={handleModalInterrupcaoOpen} variant="contained" endIcon={<Add />}>Criar</Button>
                            </Box>
                            {loading ? (
                                <LoadingContainer>
                                    <CircularProgress size={40} />
                                </LoadingContainer>
                            ) : !interrupcoes?.length ? (
                                <NenhumaInterrupcaoContainer>
                                    <Typography variant="body2" color="GrayText">
                                        Nenhuma interrupção em andamento
                                    </Typography>
                                </NenhumaInterrupcaoContainer>
                            ) : interrupcoes?.map((interrupcao: any) => {
                                return (
                                    <CardInterrupcaoIFood
                                        interrupcao={interrupcao}
                                        refresh={handleBuscarDetalhes}
                                        marketplaceUuid={marketplace?.uuid}
                                    />
                                )
                            })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: "1rem" }}>
                                <Typography variant="h6">Horário de funcionamento</Typography>
                                <Button onClick={handleModalHorariosOpen} variant="contained" endIcon={<Add />}>Criar</Button>
                            </Box>
                            {loading ? (
                                <LoadingContainer>
                                    <CircularProgress size={40} />
                                </LoadingContainer>
                            ) : !horarios?.shifts?.length ? (
                                <NenhumaInterrupcaoContainer>
                                    <Typography variant="body2" color="GrayText">
                                        Nenhum horário de funcionamento configurado
                                    </Typography>
                                </NenhumaInterrupcaoContainer>
                            ) : (
                                <CardHorariosFuncionamentoIFood
                                    horarios={horarios}
                                    refresh={handleBuscarDetalhes}
                                    marketplaceUuid={marketplace?.uuid}
                                />
                            )
                            }
                        </Paper>
                    </Grid>
                    {marketplace?.uuid && (
                        <>
                            <ModalCriarInterrupcaoIFood
                                close={handleModalInterrupcaoClose}
                                open={modalInterrupcaoOpen}
                                marketplaceUuid={marketplace.uuid}
                            />
                            {horarios && (
                                <ModalCriarHorarioFuncionamentoIFood
                                    close={handleModalHorariosClose}
                                    open={modalHorariosOpen}
                                    marketplaceUuid={marketplace.uuid}
                                    horarios={horarios}
                                />
                            )}
                        </>
                    )}
                </Grid>
            )}
        </PageContainer>
    );
}