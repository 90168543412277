import { Card, CircularProgress, IconButton, Typography } from "@mui/material";
import { DadoCardContainer } from "../../../pages/DetalhesMarketplace/styles";
import { Delete } from "@mui/icons-material";
import { useDataHora } from "../../../../core/hooks/useDataHora";
import { IInterrupcaoIFood } from "../../../interfaces/IInterrupcaoIFood";
import { CardInterrupcao } from "./styles";
import { useIFood } from "../../../hooks/useIFood";

interface CardInterrupcaoIFoodProps {
    interrupcao: IInterrupcaoIFood;
    marketplaceUuid?: string;
    refresh: () => void;
}

export const CardInterrupcaoIFood = ({ interrupcao, marketplaceUuid, refresh }: CardInterrupcaoIFoodProps) => {
    const { dataHoraBr } = useDataHora();

    const { loading, excluirInterrupcao } = useIFood();
    const handleExcluir = async () => {
        if(!marketplaceUuid) return;
        const interrupcaoExcluida = await excluirInterrupcao(marketplaceUuid, interrupcao.id);
        if (interrupcaoExcluida) {
            refresh();
        }
    }

    return (
        <CardInterrupcao variant="outlined">
            <DadoCardContainer>
                <Typography>{interrupcao.description}</Typography>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <IconButton color="error" onClick={handleExcluir}>
                        <Delete />
                    </IconButton>
                )}
            </DadoCardContainer>
            <DadoCardContainer>
                <Typography>Início</Typography>
                <Typography>{dataHoraBr(interrupcao.start)}</Typography>
            </DadoCardContainer>
            <DadoCardContainer>
                <Typography>Fim</Typography>
                <Typography>{dataHoraBr(interrupcao.end)}</Typography>
            </DadoCardContainer>
        </CardInterrupcao >
    );
}