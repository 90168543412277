import { Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { IMarketplace } from "../../../interfaces/IMarketplace";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceTitulo } from "../../../enum/TipoMarketplace";
import { useMarketplaces } from "../../../hooks/useMarketplaces";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";

interface ConfigurarCredenciaisWhatsAppProps {
    marketplace: IMarketplace;
    refresh: () => void;
}

export const ConfigurarCredenciaisWhatsApp = ({ marketplace, refresh }: ConfigurarCredenciaisWhatsAppProps) => {
    const [instanciaId, setInstanciaId] = useState<string | undefined>();
    const [instanciaToken, setInstanciaToken] = useState<string | undefined>();

    const {
        salvar,
        loading
    } = useMarketplaces();

    const notificar = useSnackbar();

    const handleSalvar = async () => {
        if(loading) return;

        if(!instanciaId?.length) {
            return notificar("O ID da instância é obrigatório", { severity: "error" });
        }

        if(!instanciaToken?.length) {
            return notificar("O token da instância é obrigatório", { severity: "error" });
        }

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                integracao: {
                    instanciaId,
                    instanciaToken
                }
            }
        });
        refresh();
    }

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Typography variant="h6">Credenciais</Typography>
            <TextField
                label="ID da instância"
                style={{ width: "100%", marginTop: "2rem" }}
                onChange={(e) => setInstanciaId(e.target.value)}
                value={instanciaId}
            />
            <TextField
                label="Token da instância"
                style={{ width: "100%", marginTop: "1rem" }}
                onChange={(e) => setInstanciaToken(e.target.value)}
                value={instanciaToken}
            />
            {/* <FormControl style={{ width: '100%', marginTop: "1rem" }}>
                <InputLabel>Marketplaces</InputLabel>
                <Select
                    labelId="marketplaces-label"
                    id="marketplaces"
                    label="Marketplace de referência"
                    value={marketplaceReferenciaUuid}
                    onChange={(e) => setMarketplaceReferenciaUuid(e.target.value)}
                >
                    {marketplacesValdidos && marketplacesValdidos?.map(marketplace => {
                        return (
                            <MenuItem key={marketplace?.uuid} value={marketplace?.uuid}>
                                {marketplace?.nome}
                                <Chip 
                                    sx={{
                                        background: TipoMarketplaceCor[marketplace.tipo],
                                        color: 'white',
                                        ml: 1
                                    }}
                                    label={TipoMarketplaceTitulo[marketplace.tipo]}
                                />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl> */}
            <Button
                style={{ width: "100%", marginTop: "1rem", height: "50px" }}
                variant={loading ? "outlined" : "contained"}
                disabled={loading}
                onClick={handleSalvar}
            >
                {loading ? (
                    <CircularProgress size={18}  />
                ) : "Salvar"}
            </Button>
        </Paper>
    );
}