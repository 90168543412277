export enum StatusEstabelecimentoIFood {
    'OK' = 'OK', 
    'WARNING' = 'WARNING',
    'CLOSED' = 'CLOSED',
    'ERROR' = 'ERROR'
}

export enum StatusEstabelecimentoIFoodDescricao {
    'OK' = 'Atendendo pedidos', 
    'WARNING' = 'Atenção!',
    'CLOSED' = 'Fechada',
    'ERROR' = 'Erro'
}

export enum StatusEstabelecimentoIFoodCor {
    'OK' = '#28a745', // Verde
    'WARNING' = '#ffc107', // Amarelo
    'CLOSED' = '#6c757d', // Cinza
    'ERROR' = '#dc3545' // Vermelho
}
