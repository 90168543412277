import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { ISabor } from "../../../../interfaces/ISabor";
import { ButtonsContainer, FormularioCard } from "../../styles";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { saborSchema } from "../../../../schema/saborSchema";
import { FieldValues, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { useCadastrarEditarProdutoContext } from "../../../../contexts/CadastrarEditarProdutoContext";
import { FichaTecnica } from "../../../FichaTecnica";
import { TipoControleEstoque } from "../../../../enum/TipoControleEstoque";
import { IImagemProduto } from "../../../../interfaces/IImagemProduto";
import { ImagemContainer } from "../../../ConfiguracaoPizzaContainer/styles";
import { CardUploadImagem } from "../../../../../core/components/CardUploadImagem";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { MIMETypes } from "../../../../../core/enum/MIMETypes";

interface FormularioSaborProps {
    adicionarSabor: (sabor: ISabor) => void;
    editarSabor: (sabor: ISabor) => void;
    saborEdicao?: ISabor;
    handleTab: (tab: "tabela" | "formulario") => void;
}

export const FormularioSabor = ({ adicionarSabor, editarSabor, handleTab, saborEdicao }: FormularioSaborProps) => {
    const { watch, setValue, reset, register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(saborSchema),
        mode: "onChange",
    });

    const { controleEstoque } = useCadastrarEditarProdutoContext();

    const [fichaTecnica, setFichaTecnica] = useState<IInsumo[]>([]);
    const [imagem, setImagem] = useState<undefined | File | IImagemProduto>(undefined);

    const adicionarInsumo = (insumo: IInsumo) => {
        setFichaTecnica(ficha => [...ficha, insumo]);
    }

    const excluirInsumo = (uuid: string) => {
        setFichaTecnica(ficha => ficha.filter(insumo => insumo.uuid !== uuid));
    }

    const { number } = useMoeda();

    const handleCadastrarSabor = async (sabor: FieldValues) => {
        const imagens: IImagemProduto[] = [];

        if (imagem) {
            imagens.push(imagem as IImagemProduto);
        }

        adicionarSabor({
            descricao: sabor.descricao,
            preco: number(sabor.preco),
            detalhes: sabor.detalhes,
            uuid: v4(),
            fichaTecnica: fichaTecnica ?? [],
            imagens
        });
        handleTab("tabela")
    }

    const mimetypesValidos = [
        MIMETypes["image/jpeg"],
        MIMETypes["image/jpg"],
        MIMETypes["image/png"]
    ] as string[];

    const snackbar = useSnackbar();

    const handleUploadImagem = (arquivo: File) => {
        if (!mimetypesValidos.includes(arquivo.type)) {
            return snackbar(`Arquivo inválido, tipos aceitos: (PNG, JPEG)`, { severity: "error" })
        }

        setImagem(arquivo);
    }

    const handleExcluirImagem = () => {
        setImagem(undefined);
    }

    const handleEditarSabor = async (sabor: FieldValues) => {
        if (saborEdicao) {
            const imagens: IImagemProduto[] = [];

            if (imagem) {
                imagens.push(imagem as IImagemProduto);
            }

            editarSabor({
                descricao: sabor.descricao,
                detalhes: sabor.detalhes,
                preco: number(sabor.preco),
                uuid: saborEdicao.uuid,
                fichaTecnica: fichaTecnica ?? [],
                imagens
            })
            handleTab("tabela")
        }
    }

    useEffect(() => {
        if (saborEdicao) {
            setValue("descricao", saborEdicao.descricao);
            setValue("detalhes", saborEdicao.detalhes);
            setValue("preco", saborEdicao.preco);
            setFichaTecnica(saborEdicao.fichaTecnica ?? []);
            if (saborEdicao.imagens?.length) {
                setImagem(saborEdicao.imagens[0]);
            }
        } else {
            reset();
            setFichaTecnica([]);
            setImagem(undefined);
        }
    }, [saborEdicao])

    const descricao = watch("descricao");
    const preco = watch("preco");
    const detalhes = watch("detalhes");

    return (
        <FormularioCard>
            <Typography variant="h6">
                Cadastrar sabor
            </Typography>
            <form onSubmit={handleSubmit(saborEdicao ? handleEditarSabor : handleCadastrarSabor)}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome"
                    type="text"
                    fullWidth
                    {...register("descricao")}
                    error={!!errors.descricao}
                    helperText={<>{errors.descricao?.message}</>}
                    value={descricao}
                />
                <TextField
                    type="text"
                    placeholder="Detalhes"
                    label="Detalhes"
                    {...register("detalhes")}
                    value={detalhes}
                    fullWidth
                    error={!!errors.detalhes}
                    helperText={<>{errors.detalhes?.message}</>}
                    multiline
                    sx={{ mt: 3 }}
                    rows={3}
                />
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel error={!!errors.preco} htmlFor="preco-sabor">Preço</InputLabel>
                    <OutlinedInput
                        id="preco-sabor"
                        error={!!errors.preco}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        label="Preço"
                        value={preco}
                        {...register("preco")}
                    />
                    {!!errors.preco && <FormHelperText error={true}><>{errors.preco?.message}</></FormHelperText>}
                </FormControl>
                <ImagemContainer>
                    <CardUploadImagem
                        titulo="Imagem"
                        descricao={
                            imagem
                                ? (imagem instanceof File ? imagem?.name : (imagem?.nomeOriginal ?? imagem?.nome))
                                : "Faça o upload"
                        }
                        url={imagem instanceof File ? URL.createObjectURL(imagem) : imagem?.url}
                        excluir={handleExcluirImagem}
                        upload={handleUploadImagem}
                    />
                </ImagemContainer>
                {controleEstoque == TipoControleEstoque.completo && (
                    <FichaTecnica fichaTecnica={fichaTecnica} adicionarInsumo={adicionarInsumo} excluirInsumo={excluirInsumo} />
                )}
                <ButtonsContainer>
                    <Button variant="outlined" type="button" onClick={() => handleTab("tabela")}>Cancelar</Button>
                    <Button variant="contained" type="submit">{saborEdicao ? "Atualizar" : "Cadastrar"}</Button>
                </ButtonsContainer>
            </form>
        </FormularioCard>
    );
}