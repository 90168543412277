import { useState } from "react";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, Checkbox } from "@mui/material";
import { IPedido } from "../../../../interface/IPedido";
import { LoadingContainer, NenhumPedidoContainer } from "./styles";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { IMarketplace } from "../../../../../marketplace/interfaces/IMarketplace";
import { TipoMarketplace, TipoMarketplaceCor } from "../../../../../marketplace/enum/TipoMarketplace";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { StatusPedidoPilula } from "../../../../components/StatusPedidoPilula";
import { StatusPedido } from "../../../../enum/StatusPedido";

interface TabelaPedidosProps {
    pedidos?: IPedido[],
    pedidosSelecionados?: IPedido[],
    marketplaces?: IMarketplace[];
    loadingMarketplaces?: boolean;
    loading?: boolean;
    abrirDetalhes: (uuid: string) => void;
    selecionarPedido: (pedido: IPedido) => void;
}

export const TabelaPedidos = ({ 
    loading, 
    pedidos, 
    marketplaces, 
    loadingMarketplaces, 
    abrirDetalhes,
    selecionarPedido,
    pedidosSelecionados 
}: TabelaPedidosProps) => {
    const { dataHoraBr } = useDataHora();
    const { real } = useMoeda();
    const { titulo } = useLetras();

    const [paginaAtual, setPaginaAtual] = useState(0)
    const [linhasPorPagina, setLinhasPorPagina] = useState(10)

    const indiceFinal = (paginaAtual + 1) * linhasPorPagina;
    const indiceInicial = indiceFinal - linhasPorPagina;
    const pedidosPaginaAtual = pedidos?.slice(indiceInicial, indiceFinal);

    if (loading) {
        return (
            <LoadingContainer>
                <CircularProgress size={50} color="primary" />
            </LoadingContainer>
        );
    }

    if (!pedidos?.length) {
        return (
            <NenhumPedidoContainer>
                <Typography>
                    Nenhum pedido encontrado
                </Typography>
            </NenhumPedidoContainer>
        );
    }

    const handlePaginaChange = (event: unknown, newPage: number) => {
        setPaginaAtual(newPage);
    };

    const handleLinhasPorPaginaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLinhasPorPagina(parseInt(event.target.value, 10));
        setPaginaAtual(0);
    };

    return (
        <>
            <TableContainer>
                <Table aria-label="Listagem de pedidos">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell>Nº pedido</TableCell>
                            <TableCell>Nº diário</TableCell>     
                            <TableCell>Nº externo</TableCell>                            
                            <TableCell>Data/hora</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Marketplace</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Nº itens</TableCell>
                            <TableCell>Contexto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pedidosPaginaAtual?.map((pedido) => {
                            const pedidoSelecionadoIndex = pedidosSelecionados?.findIndex(pedidoSelecionado => pedidoSelecionado.uuid == pedido.uuid);
                            const marketplace = marketplaces?.find(marketplace => marketplace.uuid == pedido.marketplace?.uuid);
                            return (
                                <TableRow
                                    key={pedido.uuid}
                                    hover
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor: 'pointer' }}
                                    onClick={() => abrirDetalhes(pedido.uuid)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={pedidoSelecionadoIndex == -1 ? false : true}
                                            onClick={(e) => {
                                                selecionarPedido(pedido);
                                                e.stopPropagation();
                                            }}
                                            inputProps={{
                                            'aria-labelledby': `checkbox-${pedido.uuid}`,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.numeroPedido}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.numeroPedidoDiario}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.numeroPedidoExterno ?? "-"}
                                    </TableCell>
                                    <TableCell>{dataHoraBr(pedido.dataHora)}</TableCell>
                                    <TableCell><StatusPedidoPilula status={pedido.status as StatusPedido} /></TableCell>
                                    <TableCell>
                                        {loadingMarketplaces 
                                            ? <CircularProgress size={10} /> 
                                            : pedido.marketplace?.tipo == TipoMarketplace.PDV 
                                                ? (
                                                    <Typography color={TipoMarketplaceCor.PDV}>
                                                        PDV
                                                    </Typography>
                                                )
                                                : marketplace 
                                                    ? <Typography color={TipoMarketplaceCor[marketplace.tipo]}>
                                                        {marketplace?.nome}
                                                    </Typography>
                                                    : <Typography>Não encontrado</Typography>
                                            }
                                    </TableCell>
                                    <TableCell>{real(pedido.valorTotal)}</TableCell>
                                    <TableCell>{pedido.produtos?.length}</TableCell>
                                    <TableCell>{titulo(pedido.tipo)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={pedidos?.length || 0}
                rowsPerPage={linhasPorPagina}
                page={paginaAtual}
                onPageChange={handlePaginaChange}
                onRowsPerPageChange={handleLinhasPorPaginaChange}
                labelRowsPerPage="Linhas por página:"
                sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, width: "100%" }}
            />
        </>
    );
};
