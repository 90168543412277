import { IMarketplace } from "../../../../interfaces/IMarketplace"
import { Box, Breadcrumbs, Button, Chip, Grid, Link, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DadoContainer, PageContainer } from "../../styles";
import { useNavigate } from "react-router-dom";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { Edit } from "@mui/icons-material";
import { IIntegracaoIFood, IIntegracaoWhatsApp } from "../../../../interfaces/IIntegracaoMarketplace";
import { ConfigurarCredenciaisWhatsApp } from "../../../../components/whatsapp/ConfigurarCredenciaisWhatsApp";
import { useWhatsApp } from "../../../../hooks/useWhatsApp";
import { ConfigurarComportamentosWhatsApp } from "../../../../components/whatsapp/ConfigurarComportamentosWhatsApp";
import { ConfigurarConexaoWhatsApp } from "../../../../components/whatsapp/ConfigurarConexaoWhatsApp";

interface CardapioOnlineContainerProps {
    marketplace: IMarketplace;
    marketplaces: IMarketplace[];
    handleRefresh: () => void;
    loadingFetch: boolean;
}

export const WhatsAppContainer = ({ marketplace, marketplaces, handleRefresh, loadingFetch }: CardapioOnlineContainerProps) => {
    const navigate = useNavigate();
    const { real } = useMoeda();

    const integracao = marketplace?.configuracao?.integracao as IIntegracaoWhatsApp;

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplace && (
                    <Typography color="text.primary">{marketplace.nome}</Typography>
                )}
            </Breadcrumbs>
            {!integracao ? (
                <Grid container spacing={2} sx={{ p: 2, mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        <ConfigurarCredenciaisWhatsApp
                            marketplace={marketplace}
                            refresh={handleRefresh}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{ p: 2, mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        <ConfigurarComportamentosWhatsApp
                            marketplaces={marketplaces}
                            marketplace={marketplace}
                            refresh={handleRefresh}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ConfigurarConexaoWhatsApp
                            marketplace={marketplace}
                            refresh={handleRefresh}
                        />
                    </Grid>
                </Grid>
            )}
        </PageContainer>
    );
}