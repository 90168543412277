import { ApiFactory } from "../../core/config/ApiFactory";
import { Environment } from "../../core/config/environment";
import { useFetch } from "../../core/hooks/useFetch";

export const useFetchEntregadores = () => {
    const api = ApiFactory.getApi("MS_ENTREGADORES");
    const { data: entregadores, isLoading, handleRefresh } = useFetch(api, "/");

    return {
        entregadores,
        isLoading,
        handleRefresh
    }
}