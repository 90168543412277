import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
`

export const ContentContainer = styled(Box)`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    @media(max-width: 1080px) {
        flex-direction: column;
    }
` 

export const FormContainer = styled(Box)`
    width: 49%;

    @media(max-width: 1080px) {
        width: 100%;
    }
`

export const ResumoPaper = styled(Paper)`
    width: 49%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 50%;

    @media(max-width: 1080px) {
        width: 100%;
        height: 100%;
        margin-top: 1rem;
    }
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    
    button {
        width: 120px;
    }
`

export const ToolbarPaper = styled(Paper)`
    margin-bottom: 1rem; 
    width: 100%; 
    display: flex; 
    align-items: center;
    padding: 2rem; 
    border-radius: 0;
    justify-content: flex-end;
    gap: 1rem;
    height: 70px;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.palette.divider};

    @media (max-width: 1280px) {
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        form {
            width: 100%;
        }
    }
`