import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";

export const NenhumComportamentoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
`

export const ComportamentosContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    margin-top: 1rem;
`

export const ComportamentoCard = styled(Card)`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const TituloComportamentoContainer = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`