import { Background, Controls, ReactFlow, useReactFlow } from "@xyflow/react";
import { useEffect, useRef, useState } from "react";
import ELK from "elkjs/lib/elk.bundled.js";
import { Box } from "@mui/material";

const elk = new ELK();

interface LayoutFlowProps {
  nodesT: any[];
  edgesT: any[];
  setSelectedNode: (node: any) => void;
  forceLayout: boolean;
  forceFitView: boolean;
}

export const LayoutFlow = ({
  nodesT,
  edgesT,
  setSelectedNode,
  forceLayout,
  forceFitView,
}: LayoutFlowProps) => {
  const { getNodes, getEdges, fitView, setCenter } = useReactFlow();
  const [nodes, setLocalNodes] = useState(nodesT);
  const [edges, setLocalEdges] = useState(edgesT);
  const reactFlowWrapper = useRef(null);
  const prevNodesLength = useRef(nodesT.length);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (nodesT.length !== prevNodesLength.current) {
      setLocalNodes(nodesT);
      setLocalEdges(edgesT);
      prevNodesLength.current = nodesT.length;
    } else if (!isDragging) {
      setLocalNodes(
        nodesT.map((node) => ({
          ...node,
          position:
            nodes.find((n) => n.id === node.id)?.position || node.position,
        }))
      );
      setLocalEdges(edgesT);
    }
  }, [nodesT, edgesT, isDragging]);

  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.direction": "DOWN",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 80,
    "elk.layered.crossingMinimization.strategy": "INTERACTIVE",
    "elk.layered.nodePlacement.strategy": "SIMPLE",
    "elk.layered.layering.strategy": "NETWORK_SIMPLEX",
    "elk.layered.spacing.edgeNodeBetweenLayers": 50,
    "elk.layered.spacing.edgeEdgeBetweenLayers": 30,
    "elk.layered.mergeEdges": false,
    "elk.spacing.componentComponent": 80,
    "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES",
    "elk.alignment": "CENTER",
    "elk.position": "HORIZONTAL",
  };

  const layoutElements = async () => {
    const graph: any = {
      id: "root",
      layoutOptions: defaultOptions,
      children: getNodes().map((node: any) => ({
        ...node,
        targetPosition: "top",
        sourcePosition: "bottom",
        width: 150,
        height: 45,
      })),
      edges: getEdges().map((edge: any) => ({
        id: edge.id,
        sources: [edge.sourceHandle || edge.source],
        targets: [edge.targetHandle || edge.target],
      })),
    };

    const { children }: any = await elk.layout(graph);

    if (children) {
      children.forEach((node: any) => {
        node.position = { x: node.x, y: node.y };
      });
      setLocalNodes(children);
    }

    window.requestAnimationFrame(() => {
      fitView();
    });
  };

  useEffect(() => {
    if (forceLayout || isDragging) {
      layoutElements();
    }
  }, [forceLayout, isDragging]);

  useEffect(() => {
    if (forceFitView) {
      fitView();
    }
  }, [forceFitView]);

  const handleNodeClick = (event: any, node: any) => {
    event.preventDefault();

    if (selectedNodeId === node.id) {
      setSelectedNode(null);
      setSelectedNodeId(null);
    } else {
      setSelectedNode(node);
      setSelectedNodeId(node.id);

      const x = node.position.x + (node.width || 150) / 2;
      const y = node.position.y + (node.height || 45) / 2;

      window.requestAnimationFrame(() => {
        setCenter(x, y, { zoom: 1.2, duration: 800 });
      });
    }
  };

  const onNodesChange = (changes: any) => {
    setLocalNodes((nds) => {
      return changes.reduce((acc: any[], change: any) => {
        if (change.type === "position" && change.dragging) {
          setIsDragging(true);
          return acc.map((node) => {
            if (node.id === change.id) {
              return {
                ...node,
                position: change.position,
              };
            }
            return node;
          });
        }
        if (change.type === "position" && !change.dragging) {
          setIsDragging(false);
        }
        return acc;
      }, nds);
    });
  };

  return (
    <Box
      sx={{
        flex: 1,
        height: { xs: "50vh", md: "80vh" },
        minHeight: "400px",
        width: "100%",
        padding: 0,
      }}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeClick={handleNodeClick}
        onNodesChange={onNodesChange}
        fitView
        panOnDrag={true}
        zoomOnScroll={true}
        nodesDraggable={true}
        minZoom={0.8}
        maxZoom={2.5}
        defaultViewport={{ x: 0, y: 0, zoom: 1 }}
      >
        <Controls showInteractive={false} />
        <Background gap={12} size={1} />
      </ReactFlow>
    </Box>
  );
};
