import { Moment } from "moment";
import { useState } from "react";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useIFood } from "../../../hooks/useIFood";
import { useDataHora } from "../../../../core/hooks/useDataHora";

interface ModalCriarInterrupcaoIFoodProps {
    marketplaceUuid: string;
    open: boolean;
    close: () => void;
}

export const ModalCriarInterrupcaoIFood = ({ marketplaceUuid, open, close }: ModalCriarInterrupcaoIFoodProps) => {
    const [inicio, setInicio] = useState<Moment | null>(null);
    const [fim, setFim] = useState<Moment | null>(null);
    const [descricao, setDescricao] = useState<string>("");
    const notificar = useSnackbar();

    const { dataEhDepois } = useDataHora();
    const { criarInterrupcao, loading } = useIFood();

    const handleCriarInterrupcao = async () => {
        if(loading) return;
        
        if (!inicio || !fim) {
            notificar("Os horários de início e fim são obrigatórios.", { severity: 'error' });
            return;
        }

        if (!inicio.isValid() || !fim.isValid()) {
            notificar("O horário informado é inválido.", { severity: 'error' });
            return;
        }

        if (!dataEhDepois(inicio, fim)) {
            notificar("O horário de início não pode ser posterior ao horário de término.", { severity: 'error' });
            return;
        }

        if (!descricao.trim()) {
            notificar("A descrição é obrigatória.", { severity: 'error' });
            return;
        }

        if (!marketplaceUuid) return;

        const hoje = new Date().toISOString().split("T")[0];
        const inicioFormatado = new Date(`${hoje}T${inicio.format("HH:mm:ss")}.000Z`).toISOString();
        const fimFormatado = new Date(`${hoje}T${fim.format("HH:mm:ss")}.000Z`).toISOString();

        const resultado = await criarInterrupcao(marketplaceUuid, inicioFormatado, fimFormatado, descricao);

        if (resultado) {
            handleClose();
        }
    };

    const handleClose = () => {
        setInicio(null);
        setFim(null);
        setDescricao("");
        close();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle>Criar Interrupção</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", mb: '1rem' }}>
                    <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                        <TimePicker
                            label="Início"
                            value={inicio}
                            onChange={(newValue) => setInicio(newValue)}
                            ampm={false}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "50%", display: "flex" }}>
                        <TimePicker
                            label="Fim"
                            value={fim}
                            onChange={(newValue) => setFim(newValue)}
                            ampm={false}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: "flex", mb: '1rem' }}>
                    <TextField
                        fullWidth
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        label="Descrição"
                    />
                </Box>
                <Box sx={{ display: "flex", gap: '2rem' }}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                    {loading ? (
                        <Button fullWidth variant="outlined" sx={{ height: '50px' }}>
                            <CircularProgress size={30} />
                        </Button>
                    ) : (
                        <Button fullWidth variant="contained" onClick={handleCriarInterrupcao} sx={{ height: '50px' }}>
                            Criar
                        </Button>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};
