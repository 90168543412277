import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFetchCategorias } from "../../../../hooks/useFetchCategorias";
import { TipoProduto } from "../../../../enum/TipoProduto";
import { IComplemento } from "../../../../interfaces/IComplemento";
import { v4 } from "uuid";

export interface CopiarComplementoProps {
    copiar: (complemento: IComplemento) => void;
    aberto: boolean;
    fechar: () => void;
}

export const ModalCopiarComplemento = ({
    copiar,
    fechar,
    aberto
}: CopiarComplementoProps) => {
    const { categorias, isLoading } = useFetchCategorias();

    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string | null>(null);
    const [produtoSelecionado, setProdutoSelecionado] = useState<string | null>(null);
    const [grupoSelecionado, setGrupoSelecionado] = useState<string | null>(null);

    const handleFechar = () => {
        setCategoriaSelecionada(null);
        setProdutoSelecionado(null);
        setGrupoSelecionado(null);
        fechar();
    }

    const handleCopiar = () => {
        if (!categoriaSelecionada) return;
        if (!produtoSelecionado) return;
        if (!grupoSelecionado) return;

        const categoria = categorias?.find(categoria => categoria?.uuid == categoriaSelecionada);

        if (!categoria) {
            return;
        }

        const produto = categoria?.produtos?.find(produto => produto?.uuid == produtoSelecionado);

        if (!produto) {
            return;
        }

        if (produto.tipo == TipoProduto.preparado) {
            const complemento = produto?.complementos?.find(complemento => complemento?.uuid == grupoSelecionado);

            if (!complemento) {
                console.log("CAI NO IF", produto.complementos, grupoSelecionado)
                return;
            }

            copiar({
                ...complemento,
                uuid: v4()
            })
        }

        if (produto.tipo == TipoProduto.pizza) {
            if (grupoSelecionado == "Massa") {
                copiar({
                    uuid: v4(),
                    descricao: "Massa",
                    numeroMinimoItens: 1,
                    numeroMaximoItens: 1,
                    obrigatorio: true,
                    itens: produto?.massas?.map(massa => ({
                        uuid: v4(),
                        descricao: massa.descricao,
                        preco: massa.preco,
                        fichaTecnica: massa.fichaTecnica,
                        imagens: massa.imagens
                    })) ?? []
                })
            }

            if (grupoSelecionado == "Sabor") {
                copiar({
                    uuid: v4(),
                    descricao: "Sabor",
                    numeroMinimoItens: 1,
                    numeroMaximoItens: produto.quantidadeSabores,
                    obrigatorio: true,
                    itens: produto?.sabores?.map(sabor => ({
                        uuid: v4(),
                        descricao: sabor.descricao,
                        preco: sabor.preco,
                        fichaTecnica: sabor.fichaTecnica,
                        imagens: sabor.imagens,
                        detalhes: sabor.detalhes
                    })) ?? []
                })
            }

            if (grupoSelecionado == "Borda") {
                copiar({
                    uuid: v4(),
                    descricao: "Borda",
                    numeroMinimoItens: 1,
                    numeroMaximoItens: 1,
                    obrigatorio: true,
                    itens: produto?.bordas?.map(borda => ({
                        uuid: v4(),
                        descricao: borda.descricao,
                        preco: borda.preco,
                        fichaTecnica: borda.fichaTecnica,
                        imagens: borda.imagens
                    })) ?? []
                })
            }
        }

        handleFechar();
    }

    const produtos = categoriaSelecionada
        ? categorias.find(cat => cat.uuid === categoriaSelecionada)?.produtos || []
        : [];

    const produto = produtos.find(prod => prod.uuid === produtoSelecionado);

    const gruposComplementos = produto?.tipo === TipoProduto.pizza
        ? ["Sabor", "Massa", "Borda"]
        : produto?.tipo === TipoProduto.preparado
            ? produto.complementos?.map(complemento => complemento.uuid) || []
            : [];

    return (
        <Dialog
            open={aberto}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleFechar}
        >
            <DialogTitle id="alert-dialog-title">Copiar complemento</DialogTitle>
            <DialogContent sx={{ minWidth: "450px" }}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Categoria</InputLabel>
                    <Select
                        label={"Categoria"}
                        value={categoriaSelecionada || ""}
                        onChange={(e) => {
                            setCategoriaSelecionada(e.target.value);
                            setProdutoSelecionado(null);
                            setGrupoSelecionado(null);
                        }}
                        disabled={isLoading || categorias.length === 0}
                    >
                        {categorias?.map((cat) => (
                            <MenuItem key={cat.uuid} value={cat.uuid}>
                                {cat.descricao}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal" disabled={!categoriaSelecionada || produtos.length === 0}>
                    <InputLabel>Produto</InputLabel>
                    <Select
                        label={"Produto"}
                        value={produtoSelecionado || ""}
                        onChange={(e) => {
                            setProdutoSelecionado(e.target.value);
                            setGrupoSelecionado(null);
                        }}
                    >
                        {produtos?.filter(produto => produto.tipo != TipoProduto.industrializado)?.map((prod) => (
                            <MenuItem key={prod.uuid} value={prod.uuid}>
                                {prod.descricao}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal" disabled={!produtoSelecionado || gruposComplementos.length === 0}>
                    <InputLabel>Complementos</InputLabel>
                    <Select
                        label={"Complementos"}
                        value={grupoSelecionado || ""}
                        onChange={(e) => setGrupoSelecionado(e.target.value)}
                    >
                        {gruposComplementos?.map((grupo, index) => {
                            let descricao = grupo;
                            
                            const categoria = categorias?.find(categoria => categoria?.uuid == categoriaSelecionada);

                            if (categoria) {
                                const produto = categoria?.produtos?.find(produto => produto?.uuid == produtoSelecionado);
                                if (produto) {
                                    const complemento = produto?.complementos?.find(complemento => complemento.uuid == grupo);
                                    if(complemento) {
                                        descricao = complemento.descricao;
                                    }
                                }   
                            }

                            return (
                                <MenuItem key={index} value={grupo}>
                                    {descricao}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleFechar} color="primary">
                    Cancelar
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleCopiar()}
                    color="primary"
                    disabled={!categoriaSelecionada || !produtoSelecionado || !grupoSelecionado}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
