import * as yup from "yup";
import { validarTelefone } from "../../core/utils/validarTelefone";
import { validarCpf } from "../../core/utils/validarCpf";
import { validarCnpj } from "../../core/utils/validarCnpj";

export const cadastrarEntregadorSchema =  yup.object().shape({
  email: yup.string().email("Endereço de e-mail inválido"),
  nome: yup.string().required("O nome é obrigatório"),
  telefone: yup.string().required("O telefone é obrigatório").test('telefone', 'Telefone inválido', (value) => validarTelefone(value, false)),
  cpf: yup.string().test('cpf', 'CPF inválido', (value) => validarCpf(value, false)),
  cnpj: yup.string().test('cnpj', 'CNPJ inválido', (value) => validarCnpj(value, false)),
});
  