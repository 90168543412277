import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { IPedido } from "../../../../../../interface/IPedido";
import { useEffect, useState } from "react";
import { useIFood } from "../../../../../../../marketplace/hooks/useIFood";
import { TipoMarketplace } from "../../../../../../../marketplace/enum/TipoMarketplace";
import { ICancelamentoPedido } from "../../../../../../interface/ICancelamentoPedido";
import { StatusPedido, StatusPedidoDescricao } from "../../../../../../enum/StatusPedido";
import { IPagamentoPedido } from "../../../../../../interface/IPagamentoPedido";

interface ModalCancelarPedidoProps {
    open: boolean;
    fechar: () => void;
    atualizarStatus: (props: {
        pedidoUuid: string, 
        status: StatusPedido | string, 
        pagamentos?: IPagamentoPedido[],
        cancelamento?: ICancelamentoPedido
    }) => Promise<void>;
    loading?: boolean;
    pedido?: IPedido;
}

const cancelamentosPadrao: ICancelamentoPedido[] = [
    {
        descricao: 'Pedido duplicado'
    },
    {
        descricao: 'Item indisponível'
    },
    {
        descricao: 'Loja está sem entregadores disponíveis'
    },
    {
        descricao: 'O cardápio está desatualizado'
    },
    {
        descricao: 'Pedido fora da área de entrega'
    },
    {
        descricao: 'Suspeita de golpe ou trote'
    },
    {
        descricao: 'Pedido realizado fora do horário de funcionamento'
    },
    {
        descricao: 'Indisponibilidade interna'
    },
    {
        descricao: 'Entrega em área de risco'
    }
]

export const ModalCancelarPedido = ({ open, fechar, atualizarStatus, pedido, loading }: ModalCancelarPedidoProps) => {
    const { buscarMotivosCancelamento } = useIFood();
    const [cancelamentos, setCancelamentos] = useState<ICancelamentoPedido[]>([]);
    const [cancelamento, setCancelamento] = useState<ICancelamentoPedido>();

    const handleBuscarMotivos = async () => {
        if(!open) return;
        
        const statusNaoBuscar = [
            StatusPedido.Cancelado,
            StatusPedido.Concluído,
            StatusPedido["Em negociação"],
        ];
        
        if(pedido?.status && statusNaoBuscar.includes(pedido?.status as StatusPedido)) {
            return;
        }

        if (pedido?.marketplace?.tipo == TipoMarketplace.IFOOD && pedido?.marketplace?.uuid) {
            const motivos = await buscarMotivosCancelamento(
                pedido.marketplace?.uuid,
                pedido.uuid
            );

            if (!motivos?.length) return;

            const cancelamentosFormatados: ICancelamentoPedido[] = motivos?.map(motivo => ({
                descricao: motivo.description,
                codigoExterno: motivo.cancelCodeId
            }));

            setCancelamentos(cancelamentosFormatados);
            return;
        }

        setCancelamentos(cancelamentosPadrao);
    };

    useEffect(() => {
        handleBuscarMotivos();
    }, [pedido, open]);

    const handleChangeCancelamento = (event: any) => {
        const descricaoSelecionado = event.target.value;
        const cancelamentoSelecionado = cancelamentos.find(c => c.descricao === descricaoSelecionado);
        setCancelamento(cancelamentoSelecionado);
    };

    const handleCancelarPedido = async () => {
        if(!pedido?.uuid) return;

        await atualizarStatus({
            pedidoUuid: pedido?.uuid,
            status: StatusPedidoDescricao.Cancelado,
            cancelamento
        })

        fechar();
    }

    return (
        <Dialog open={open} onClose={fechar} maxWidth="md">
            <DialogTitle>Cancelar pedido #{pedido?.numeroPedido}</DialogTitle>
            <DialogContent sx={{ minWidth: "400px" }}>
                <Typography variant="subtitle1">
                    Selecione o motivo de cancelamento
                </Typography>
                <FormControl style={{ width: '100%', margin: "0.7rem 0" }}>
                    <InputLabel>Motivo</InputLabel>
                    <Select
                        labelId="cancelamentos-label"
                        id="cancelamentos"
                        label="Motivo"
                        value={cancelamento ? cancelamento.descricao : ""}
                        onChange={handleChangeCancelamento}
                    >
                        {cancelamentos && cancelamentos.map((cancelamento, index) => (
                            <MenuItem key={index} value={cancelamento.descricao}>
                                {cancelamento.descricao}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Alert severity="warning">
                    <AlertTitle>A ação não pode ser revertida</AlertTitle>
                    Após confirmar não será possível reverter o status de cancelamento.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={fechar} variant="outlined">Cancelar</Button>
                {loading ? (
                    <CircularProgress sx={{ width: 15, height: 15 }}/>
                ) : (
                    <Button variant="contained" color="warning" onClick={handleCancelarPedido}>
                    Confirmar cancelamento
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
