export enum TipoFluxoComanda {
    "nota_nao_fiscal" = "nota_nao_fiscal",
    "comanda_acrescimo_produto_cozinha" = "comanda_acrescimo_produto_cozinha",
    "nota_fiscal" = "nota_fiscal",
}

export enum TipoFluxoComandaTitulo {
    "nota_nao_fiscal" = "Nota não fiscal",
    "comanda_acrescimo_produto_cozinha" = "Comanda de cozinha",
    "nota_fiscal" = "Nota fiscal",
}

export enum TipoFluxo {
    "simples" = "simples",
    "delivery" = "delivery",
    "retirada" = "retirada",
    "mesa" = "mesa",
}

export enum TipoFluxoTitulo {
    "simples" = "Simples",
    "delivery" = "Delivery",
    "retirada" = "Retirada",
    "mesa" = "Mesa",
}