import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";
import { AxiosError } from "axios";
import { IMarketplace } from "../interfaces/IMarketplace";
import { IMotivoCancelamentoIFood } from "../interfaces/IMotivoCancelamentoIFood";
import { IStatusEstabelecimentoIFood } from "../interfaces/IStatusEstabelecimentoIFood";
import { IHorarioFuncionamentoIFood, IHorariosFuncionamentoIFood } from "../interfaces/IHorariosFuncionamentoIFood";

export const useIFood = () => {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiMarketplaces = useMemo(() => ApiFactory.getApi("MS_MARKETPLACES"), []);

    const listarCatalogos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        contexto: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/catalogos`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarHorarios = async (marketplaceUuid: string): Promise<any> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos/horarios`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao listar os horários", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao listar os horários", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarEstabelecimentos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        nome: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscarDetalhes = async (marketplaceUuid: string): Promise<any> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos/detalhes`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao buscar os detalhes", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao buscar os detalhes", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const consultarStatus = async (marketplaceUuid: string): Promise<IStatusEstabelecimentoIFood[] | null> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos/status`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao consultar o status", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao consultar o status", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarInterrupcoes = async (marketplaceUuid: string): Promise<any> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/estabelecimentos/interrupcoes`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao listar as interrupções", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao consultar as interrupções", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluirInterrupcao = async (marketplaceUuid: string, id: string): Promise<any> => {
        if(loading) {
            return false;
        }
        setLoading(true);

        try { 
            await apiMarketplaces.delete(`/ifood/${marketplaceUuid}/estabelecimentos/interrupcoes/${id}`);
            return true;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }

            showSnackbar("Ocorreu um erro ao excluir a interrupção", { severity: "error" });
            return false;
        } finally {
            setLoading(false);
        }
    }

    const criarInterrupcao = async (marketplaceUuid: string, inicio: string, fim: string, descricao: string): Promise<any> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/ifood/${marketplaceUuid}/estabelecimentos/interrupcoes`, {
                inicio,
                fim, 
                descricao
            });
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao criar a interrupção", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const vincular = async (
        marketplaceUuid: string,
        authorizationCode?: string,
        authorizationCodeVerifier?: string
    ): Promise<null | {
        userCode?: string;
        authorizationCodeVerifier?: string;
        verificationUrlComplete?: string;
        accessToken?: string;
        refreshToken?: string;
    }> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/ifood/${marketplaceUuid}/vincular`, {
                authorizationCode,
                authorizationCodeVerifier
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscarMotivosCancelamento = async (marketplaceUuid: string, pedidoUuid: string): Promise<IMotivoCancelamentoIFood[] | null> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/ifood/${marketplaceUuid}/pedidos/${pedidoUuid}/motivos-cancelamento`);

            if(!data) {
                showSnackbar("Ocorreu um erro ao listar os motivos de cancelamento", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const salvarHorarios =  async (marketplaceUuid: string, horarios: IHorariosFuncionamentoIFood) => {
        if(loading) {
            return false;
        }
        setLoading(true);

        try { 
            await apiMarketplaces.post(`/ifood/${marketplaceUuid}/estabelecimentos/horarios`, {
                horarios
            });

            return true;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }

            showSnackbar("Ocorreu um erro ao salvar os horários", { severity: "error" });
            return false;
        } finally {
            setLoading(false);
        }
    }

    return { 
        listarEstabelecimentos, 
        listarCatalogos, 
        vincular, 
        loading, 
        buscarMotivosCancelamento, 
        buscarDetalhes, 
        consultarStatus, 
        listarInterrupcoes, 
        criarInterrupcao, 
        excluirInterrupcao,
        listarHorarios,
        salvarHorarios
    }
}