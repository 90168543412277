import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, Checkbox, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { ButtonsContainer, ButtonsContainerForm, ConfiguracaoSabores, FiltroContainer, FormularioCard, TabelaVazia } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTamanhoTela } from '../../../core/hooks/useTamanhoTela';
import { ISabor } from '../../interfaces/ISabor';
import { saborSchema } from '../../schema/saborSchema';
import { FormularioSabor } from './containers/FormularioSabor';
import { Add, ArrowDropDown } from '@mui/icons-material';
import { ConfirmacaoAcaoModal } from '../../../core/components/ConfirmacaoAcaoModal';
import { ModalAtualizaPreco } from '../ModalAtualizarPreco';

type Tabs = "tabela" | "formulario";

interface ConfiguracaoSaboresContainerProps { }

export const ConfiguracaoSaboresContainer = ({ }: ConfiguracaoSaboresContainerProps) => {
    const [saboresSelecionados, setSaboresSelecionados] = useState<string[]>([]);
    const [filtroSabores, setFiltroSabores] = useState("");
    const { sabores, adicionarSabor, excluirSabor, editarSabor, handleProximo, handleVoltar } = useCadastrarEditarProdutoContext();
    const [tab, setTab] = useState<Tabs>("tabela");
    const [saborEdicao, setSaborEdicao] = useState<ISabor | undefined>(undefined)
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState<boolean>(false);
    const [atualizacaoPreco, setAtualizacaoPreco] = useState<boolean>(false);
    const { number, real } = useMoeda();
    const { modeloTela } = useTamanhoTela();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleAbrirAtualizacaoPreco = () => {
        setAtualizacaoPreco(true);
        handleClose();
    }

    const handleFecharAtualizacaoPreco = () => {
        setAtualizacaoPreco(false);
    }

    const handleAtualizarPreco = (preco: string) => {
        saboresSelecionados?.map(uuidSabor => {
            const sabor = sabores?.find(sabor => sabor.uuid == uuidSabor);
            if (sabor) {
                editarSabor({
                    ...sabor,
                    preco: number(preco)
                });
            }
        })

        handleFecharAtualizacaoPreco();
        limparSaboresSelecionados();
    }

    const limparSaboresSelecionados = () => {
        setSaboresSelecionados([]);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTab = (tab: Tabs) => {
        setTab(tab)
        setSaborEdicao(undefined);
        limparSaboresSelecionados();
    }

    const handleExcluirSabores = async () => {
        saboresSelecionados?.map(uuidSabor => {
            const sabor = sabores?.find(sabor => sabor.uuid == uuidSabor);
            if (sabor) {
                excluirSabor(sabor);
            }
        })
        handleFecharExclusao();
        limparSaboresSelecionados();
    }

    const handleAbrirExclusao = async () => {
        setConfirmacaoExclusao(true);
        handleClose();
    }

    const handleFecharExclusao = async () => {
        setConfirmacaoExclusao(false);
    }

    const handleAbrirEdicao = (sabor: ISabor) => {
        setTab("formulario");
        setSaborEdicao(sabor);
        handleClose();
    }

    const toggleSelecionado = (uuid: string) => {
        setSaboresSelecionados(prev =>
            prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
        );
    };

    const toggleSelecionarTodos = () => {
        if (saboresSelecionados.length === sabores.length) {
            limparSaboresSelecionados();
        } else {
            setSaboresSelecionados(sabores.map(sabor => sabor.uuid));
        }
    };


    const renderizarLinha = (sabor: ISabor) => (
        <TableRow>
            <TableCell align="center">
                <Checkbox
                    checked={saboresSelecionados.includes(sabor.uuid)}
                    onChange={() => toggleSelecionado(sabor.uuid)}
                />
            </TableCell>
            <TableCell align="center">
                <img alt={sabor.descricao} src={
                    sabor?.imagens?.length
                        ? sabor?.imagens[0] instanceof File ? URL.createObjectURL(sabor?.imagens[0]) : sabor?.imagens[0].url
                        : `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                } style={{
                    width: "60px",
                    height: "50px",
                    borderRadius: "5px"
                }} />
            </TableCell>
            <TableCell align="center">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'left'
                }}>
                    <Typography fontSize={14}>
                        {sabor.descricao}
                    </Typography>
                    {sabor.detalhes && (
                        <Typography color={"GrayText"} fontSize={10}>
                            {sabor.detalhes?.length > 35 ? `${sabor.detalhes?.slice(0, 35)}...` : sabor.detalhes}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            <TableCell align="center">
                <Typography fontSize={13}>
                    {real(sabor.preco)}
                </Typography>
            </TableCell>
        </TableRow>
    );

    return (
        <ConfiguracaoSabores>
            {tab == "tabela" && (
                <TableContainer component={Paper} variant='outlined' sx={{
                    width: "100%",
                    maxHeight: 450,
                    height: "100%",
                    minHeight: 350
                }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="h6">
                            Sabores
                        </Typography>
                        <FiltroContainer>
                            {modeloTela == "desktop" && (
                                <TextField
                                    placeholder='Nome'
                                    size='small'
                                    value={filtroSabores}
                                    onChange={(e) => setFiltroSabores(e.target.value)}
                                    sx={{ width: "200px" }}
                                />
                            )}
                            <Box>
                                <Button
                                    onClick={handleClick}
                                    endIcon={<ArrowDropDown />}
                                    variant="contained"
                                    disabled={saboresSelecionados.length <= 0}
                                >
                                    Ações
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem disabled>
                                        <Typography variant="inherit" noWrap>
                                            {saboresSelecionados.length} {saboresSelecionados.length === 1 ? "sabor selecionado" : "sabores selecionados"}
                                        </Typography>
                                    </MenuItem>
                                    <Divider />
                                    <Tooltip title={saboresSelecionados?.length <= 1 ? 'Editar' : 'Alterar preço'}>
                                        <MenuItem disabled={saboresSelecionados?.length == 0} onClick={() => {
                                            if (saboresSelecionados?.length == 1) {
                                                const sabor = sabores?.find(sabor => sabor.uuid == saboresSelecionados[0])
                                                if (sabor) {
                                                    return handleAbrirEdicao(sabor);
                                                }
                                            }
                                            handleAbrirAtualizacaoPreco();
                                        }}>
                                            <ListItemIcon>
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                {saboresSelecionados?.length <= 1 ? 'Editar' : 'Alterar preço'}
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                    <Tooltip title={saboresSelecionados?.length == 1 ? `Excluir sabor` : `Excluir sabores`}>
                                        <MenuItem onClick={handleAbrirExclusao} disabled={saboresSelecionados?.length == 0}>
                                            <ListItemIcon>
                                                <DeleteIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                Excluir
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                </Menu>
                            </Box>
                            <Tooltip title='Crie um novo sabor'>
                                <IconButton color="primary" onClick={() => handleTab("formulario")}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </FiltroContainer>
                    </Toolbar>
                    {sabores.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={saboresSelecionados.length === sabores.length && sabores.length > 0}
                                            indeterminate={saboresSelecionados.length > 0 && saboresSelecionados.length < sabores.length}
                                            onChange={toggleSelecionarTodos}
                                        />

                                    </TableCell>
                                    <TableCell align="center">Imagem</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Preço</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filtroSabores
                                    ? sabores.filter(sabor => sabor.descricao.toLowerCase().includes(filtroSabores.toLocaleLowerCase())).map(sabor => {
                                        return renderizarLinha(sabor);
                                    })
                                    : sabores.map(sabor => {
                                        return renderizarLinha(sabor);
                                    })}

                            </TableBody>
                        </Table>
                    ) : (
                        <TabelaVazia>
                            Nenhum sabor cadastrado
                        </TabelaVazia>
                    )}
                </TableContainer>
            )}
            {tab == "formulario" && <FormularioSabor
                adicionarSabor={adicionarSabor}
                editarSabor={editarSabor}
                handleTab={handleTab}
                saborEdicao={saborEdicao}
            />}
            {tab == "tabela" && (
                <ButtonsContainerForm>
                    <Button size="medium" variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                </ButtonsContainerForm>
            )}
            <ConfirmacaoAcaoModal
                open={confirmacaoExclusao}
                descricao={saboresSelecionados?.length == 1
                    ? `Você deseja excluir o sabor selecionado?`
                    : `Você deseja excluir os ${saboresSelecionados?.length} sabores selecionados?`
                }
                titulo={saboresSelecionados?.length == 1 ? `Excluir sabor` : `Excluir sabores`}
                onCancelar={handleFecharExclusao}
                onConfirmar={handleExcluirSabores}
            />
            <ModalAtualizaPreco
                aberto={atualizacaoPreco}
                fechar={handleFecharAtualizacaoPreco}
                atualizar={handleAtualizarPreco}
            />
        </ConfiguracaoSabores>
    )
}