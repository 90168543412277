import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";

export const CardHorarios = styled(Card)`
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
`

export const CalendarContainer = styled(Box)`
    .fc-event {
        cursor: pointer !important;
    }

`