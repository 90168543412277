import styled from "@emotion/styled"
import { Box, Paper } from "@mui/material"

export const ItensPaper = styled(Paper)`
    width: 100%;
    height: 360px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    margin-top: 1rem;
`

export const ItemCard = styled(Paper)`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0.5rem;
    gap: 1rem;
`

export const ItemImage = styled(Box)`
    width: 70px;
    height: 70px;
    border-radius: 5px;

    img {
        border-radius: 5px;
        width: 70px;
        height: 70px;
    }
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
`

export const FiltroContainer = styled(Box)`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        margin-left: 0.5rem;
    }

`