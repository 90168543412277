import { Autocomplete, Box, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import { IComplemento } from "../../../../interfaces/IComplemento";
import { useMemo, useState } from "react";
import { IItemProduto } from "../../../../../pedidos/interface/IItemProduto";
import { IITemComplemento } from "../../../../interfaces/IITemComplemento";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useMoeda } from "../../../../../core/hooks/useMoeda";

interface ComplementoPaperInputProps {
    complemento: IComplemento
    definirItensComplemento: (complementoUuid: string, itens: IITemComplemento[]) => void;
}

export const ComplementoPaperInput = ({ complemento, definirItensComplemento }: ComplementoPaperInputProps) => {
    const { real } = useMoeda();
    const snackbar = useSnackbar();

    const handleItensChange = (event: React.SyntheticEvent<Element, Event>, value: IITemComplemento[]) => {
        if (complemento.numeroMaximoItens) {
            if (value.length > complemento.numeroMaximoItens) {
                return snackbar(`Você já selecionou os ${complemento.numeroMaximoItens} itens`, { severity: "error" })
            }
        }

        definirItensComplemento(complemento.uuid, value);
    }

    const total = useMemo(() => {
        let total = 0;

        complemento.itensSelecionados?.map(item => {
            total = total + item.preco;
        })

        return total;
    }, [complemento.itensSelecionados])

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Typography gutterBottom variant="h6" component="div">
                        {complemento.descricao}
                    </Typography>
                    <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                        {real(total)}
                    </Typography>
                </Stack>
                {complemento.itens && (
                    <Autocomplete
                        multiple
                        value={complemento.itensSelecionados}
                        noOptionsText="Nenhuma opção encontrada"
                        onChange={handleItensChange}
                        options={complemento.itens?.filter(item => !item.inativadoAs)}
                        getOptionLabel={(item) => item.descricao}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Itens"
                                inputRef={input => input && !complemento.itens.length && input.focus()}
                            />
                        )}
                        sx={{ width: '100%', marginTop: "0.5rem" }}
                    />
                )}
            </Box>
            <Divider />
        </>
    );
}