import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from "../../contexts/DrawerContext";
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { useThemeContext } from "../../contexts/ThemeContext";
import { ShoppingCart, SportsMotorsports, Storefront, Settings } from "@mui/icons-material";
import { useAutenticacaoContext } from "../../contexts/AutenticacaoContext";

export const ModulosDrawer = () => {
    const { isOpen, closeDrawer } = useDrawerContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { theme } = useThemeContext();

    const redirect = (pathname: string) => {
        navigate(pathname);
        closeDrawer();
    }

    const { verificarAcessoModulo, verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            onClose={closeDrawer}
            sx={{ zIndex: 99999 }}
        >
            <Box
                sx={{ width: '300px' }}
                role="presentation"
            >
                <List>
                    <ListItem onClick={() => redirect('/dashboard')} key="Dashboard" disablePadding>
                        <ListItemButton sx={{ height: "40px" }}>
                            <ListItemIcon>
                                <InsightsIcon style={location.pathname.includes("/dashboard") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" style={location.pathname.includes("/dashboard") ? { color: theme.palette.primary.main } : {}} />
                        </ListItemButton>
                    </ListItem>
                    {perfil && verificarAcessoModulo("Gestão de usuários") && (
                        <ListItem onClick={() => redirect('/usuarios')} key="Usuários" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <GroupIcon style={location.pathname.includes("/usuarios") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" style={location.pathname.includes("/usuarios") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem  onClick={() => redirect('/pedidos/operacao')} key="Pedidos" disablePadding>
                        <ListItemButton sx={{ height: "40px" }}>
                            <ListItemIcon>
                                <ReceiptLongIcon style={location.pathname.includes("/pedidos") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemIcon>
                            <ListItemText primary="Pedidos" style={location.pathname.includes("/pedidos") ? { color: theme.palette.primary.main } : {}} />
                        </ListItemButton>
                    </ListItem>
                    {perfil && verificarAcessoModulo("Financeiro") && (
                        <ListItem onClick={() => redirect('/financeiro/caixa')} key="Financeiro" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <AttachMoneyIcon style={location.pathname.includes("/financeiro") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Financeiro" style={location.pathname.includes("/financeiro") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {perfil && verificarAcessoModulo("Cardápio") && (
                        <ListItem onClick={() => redirect('/cardapio')} key="Cardápio" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <RestaurantIcon style={location.pathname.includes("/cardapio") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Cardápio" style={location.pathname.includes("/cardapio") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {perfil && verificarAcessoModulo("Gestão de clientes") && (
                        <ListItem onClick={() => redirect('/clientes/listar')} key="Clientes" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <RoomServiceIcon style={location.pathname.includes("/clientes") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Clientes" style={location.pathname.includes("/clientes") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {perfil && verificarAcessoModulo("Configurações") && (
                        <ListItem onClick={() => redirect('/configuracoes')} key="Configurações" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <Settings style={location.pathname.includes("/configuracoes") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Configurações" style={location.pathname.includes("/configuracoes") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {perfil && (verificarAcessoModulo("Marketplaces - Cozinheiro") || verificarAcessoModulo("Marketplaces - Aprendiz")) && (
                        <ListItem onClick={() => redirect('/marketplace')} key="Marketplace" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <ShoppingCart style={location.pathname.includes("/marketplace") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Marketplace" style={location.pathname.includes("/marketplace") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {perfil && verificarAcessoModulo("Entregadores") && (
                        <ListItem onClick={() => redirect('/entregadores')} key="Entregadores" disablePadding>
                            <ListItemButton sx={{ height: "40px" }}>
                                <ListItemIcon>
                                    <SportsMotorsports style={location.pathname.includes("/entregadores") ? { color: theme.palette.primary.main } : {}} />
                                </ListItemIcon>
                                <ListItemText primary="Entregadores" style={location.pathname.includes("/entregadores") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem onClick={() => redirect('/estabelecimentos')} key="Estabelecimentos" disablePadding>
                        <ListItemButton sx={{ height: "40px" }}>
                            <ListItemIcon>
                                <Storefront style={location.pathname.includes("/estabelecimentos") ? { color: theme.palette.primary.main } : {}} />
                            </ListItemIcon>
                            <ListItemText primary="Estabelecimentos" style={location.pathname.includes("/estabelecimentos") ? { color: theme.palette.primary.main } : {}} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}