import { Button, Chip, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IMarketplace } from "../../../interfaces/IMarketplace";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceTitulo } from "../../../enum/TipoMarketplace";
import { useMarketplaces } from "../../../hooks/useMarketplaces";
import { useSnackbar } from "../../../../core/contexts/SnackbarContext";
import { useWhatsApp } from "../../../hooks/useWhatsApp";
import { AlterarNumeroContainer, ButtonsContainer, CodigoContainer, ConexaoContainer, QRCodeContainer } from "./styles";
import { useMascara } from "../../../../core/hooks/useMascara";
import { validarTelefone } from "../../../../core/utils/validarTelefone";
import { Check, CheckCircle, CheckCircleOutline } from "@mui/icons-material";

interface ConfigurarConexaoWhatsAppProps {
    marketplace: IMarketplace;
    refresh: () => void;
}

export const ConfigurarConexaoWhatsApp = ({ marketplace, refresh }: ConfigurarConexaoWhatsAppProps) => {
    const [tab, setTab] = useState<'qr-code' | 'codigo'>('qr-code');
    const [qrCode, setQrCode] = useState<string | undefined>();
    const [codigo, setCodigo] = useState<string | undefined>();
    const [status, setStatus] = useState<undefined | boolean>()
    const [telefone, setTelefone] = useState<string | undefined>(undefined);
    const { mascaraTelefone } = useMascara();

    const { gerarQRCode, gerarCodigo, consultarStatus, loading: loadingWpp } = useWhatsApp();

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!marketplace?.uuid) return;

            const statusAtual = await consultarStatus(marketplace.uuid);
            setStatus(statusAtual);
        }, 2000);

        return () => clearInterval(interval);
    }, [marketplace?.uuid, consultarStatus]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!marketplace?.uuid || status) return;
            
            if (tab === 'qr-code') {
                const qrCodeBase64 = await gerarQRCode(marketplace.uuid);
                setQrCode(qrCodeBase64);
            } else if (tab === 'codigo' && telefone && validarTelefone(telefone)) {
                const codigoGerado = await gerarCodigo(marketplace.uuid, telefone);
                if (codigoGerado) setCodigo(codigoGerado);
            }
        }, 5000);
    
        return () => clearInterval(interval);
    }, [marketplace?.uuid, status, tab, telefone, gerarQRCode, gerarCodigo]);

    const notificar = useSnackbar();

    const handleConsultarQRCode = async () => {
        if (!marketplace?.uuid) return;
        if(status) return;

        const qrCodeBase64 = await gerarQRCode(marketplace.uuid);
        setQrCode(qrCodeBase64);
    }

    const handleGerarCodigo = async () => {
        if (!marketplace?.uuid) return;
        if(status) return;

        if (!telefone?.length || !validarTelefone(telefone)) {
            return notificar("Número de telefone inválido", { severity: 'error' })
        }

        const codigoGerado = await gerarCodigo(marketplace.uuid, telefone);
        if (codigoGerado) setCodigo(codigoGerado);
    }

    const handleTab = async (tab: 'qr-code' | 'codigo') => {
        setQrCode(undefined);
        setTelefone(undefined);
        setTab(tab);
    }

    const handleAlterarTelefone = async () => {
        setTelefone(undefined);
        setCodigo(undefined);
    }

    useEffect(() => {
        handleConsultarQRCode();
    }, [tab])

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Typography variant="h6">Conexão</Typography>
            {status ? (
                <ConexaoContainer>
                    <Check sx={{ fontSize: 40, mb: 1 }} color="primary" />
                    <Typography variant="h6" color="primary">
                        Número conectado com sucesso
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                        Agora seu WhatsApp está pronto para automatizar o seu atendimento.
                    </Typography>
                </ConexaoContainer>
            ) : (
                <>
                    {tab == 'qr-code' ? (
                        <QRCodeContainer>
                            {loadingWpp ? (
                                <CircularProgress size={60} />
                            ) : (
                                <>
                                    <Typography sx={{ mb: 1 }} variant="body1">Leia o QR através do WhatsApp</Typography>
                                    {qrCode && (
                                        <img src={qrCode} style={{ width: "300px", height: "300px" }} />
                                    )}
                                    <Divider
                                        orientation="horizontal"
                                        variant="middle"
                                        flexItem
                                        sx={{ mb: 2, mt: 2 }}
                                    >
                                        Ou
                                    </Divider>
                                    <Button onClick={() => handleTab('codigo')}>
                                        Conecte com o número de telefone
                                    </Button>
                                </>
                            )}
                        </QRCodeContainer>
                    ) : (
                        <CodigoContainer>
                            {codigo ? (
                                <>
                                    <AlterarNumeroContainer variant="outlined">
                                        <Typography variant="body1">
                                            {telefone}
                                        </Typography>
                                        <Button size="small" onClick={handleAlterarTelefone}>
                                            Alterar
                                        </Button>
                                    </AlterarNumeroContainer>
                                    <Typography
                                        variant="h5"
                                    >
                                        {codigo}
                                    </Typography>
                                    <ButtonsContainer>
                                        <Button
                                            disabled={loadingWpp}
                                            fullWidth
                                            onClick={() => handleTab('qr-code')}
                                            sx={{ mt: "2rem" }}
                                        >
                                            Conectar com QR Code
                                        </Button>
                                    </ButtonsContainer>
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ mb: 1 }} variant="body1">Digite o número do telefone</Typography>
                                    <TextField
                                        label="Telefone"
                                        style={{ width: "100%", margin: "1rem 0" }}
                                        value={telefone}
                                        onChange={(e) => setTelefone(mascaraTelefone(e.target.value))}
                                    />
                                    <ButtonsContainer>
                                        <Button
                                            disabled={loadingWpp}
                                            fullWidth
                                            variant="outlined"
                                            onClick={() => handleTab('qr-code')}
                                            sx={{ height: '50px' }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant={loadingWpp ? 'outlined' : "contained"}
                                            onClick={() => handleGerarCodigo()}
                                            disabled={loadingWpp}
                                            sx={{ height: '50px' }}
                                        >
                                            {
                                                loadingWpp
                                                    ? <CircularProgress size={15} />
                                                    : 'Gerar'
                                            }
                                        </Button>
                                    </ButtonsContainer>
                                </>
                            )
                            }
                        </CodigoContainer >
                    )}
                </>
            )}


        </Paper >
    );
}