import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";

export const QRCodeContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
`

export const ConexaoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80%;
`

export const CodigoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
`

export const AlterarNumeroContainer = styled(Card)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 1rem;
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
`
