import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Autocomplete, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useLetras } from "../../../core/hooks/useLetras";
import { TipoPedido } from "../../../pedidos/enum/TipoPedido";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";
import { useMascara } from "../../../core/hooks/useMascara";
import { useFetchPerfis } from "../../../perfis/hooks/useFetchPerfis";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { Plataforma } from "../../../core/enum/Plataforma";
import { useEntregadores } from "../../hooks/useEntregadores";
import { cadastrarEntregadorSchema } from "../../schemas/cadastrarEntregadorSchema";

export const CadastrarEditarEntregador = () => {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { estabelecimento, verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    const { perfis } = useFetchPerfis();
    const snackbar = useSnackbar();
    const { titulo } = useLetras();
    const { setValue, reset, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(cadastrarEntregadorSchema),
        mode: "onChange",
    });

    const { loading, salvar, buscar } = useEntregadores();
    const { mascaraCPF, mascaraCNPJ ,mascaraTelefone } = useMascara();


    useEffect(() => {
        if (uuid) {
            buscar(uuid).then((entregador) => {
                if (entregador) {
                    reset(entregador);
                }
            });
        }
    }, [uuid]);

    const handleSubmitForm = async (entregador: FieldValues) => {
        if (!estabelecimento) {
            return;
        }

        const resultadoCadastro = await salvar({
            email: entregador.email,
            nome: entregador.nome,
            telefone: entregador.telefone,
            cpf: entregador.cpf,
            cnpj: entregador.cnpj,
            uuid
        });

        if (resultadoCadastro) {
            snackbar(`Entregador ${uuid ? 'editado' : 'cadastrado'} com sucesso`, { severity: "success" });
            
            if(uuid) {
                return navigate('/entregadores');
            }
            reset();
        }
    }

    useEffect(() => {
        if (perfil && !verificarAcessoFuncionalidade("Cadastro de entregador")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <PageContainer>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" href="/entregadores">
                        Base de entregadores
                    </Link>
                    <Typography color="text.primary">
                        {uuid ? "Editar" : "Cadastrar"} entregador
                    </Typography>
                </Breadcrumbs>
                <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                    {uuid ? "Editar" : "Cadastrar"} entregador
                </Typography>
                <ContentContainer>
                    <FormContainer>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">Dados do entregador</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Nome*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("nome")}
                                    error={!!errors.nome}
                                    helperText={<>{errors.nome?.message}</>}
                                    InputLabelProps={{
                                        shrink: watch('nome')?.length ? true : false
                                    }}
                                />
                                <TextField
                                    label="E-mail"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("email")}
                                    error={!!errors.email}
                                    helperText={<>{errors.email?.message}</>}
                                    InputLabelProps={{
                                        shrink: watch('email')?.length ? true : false
                                    }}
                                />
                                <TextField
                                    label="Telefone*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("telefone")}
                                    value={mascaraTelefone(watch("telefone"))}
                                    error={!!errors.telefone}
                                    helperText={<>{errors.telefone?.message}</>}
                                    InputLabelProps={{
                                        shrink: watch('telefone')?.length ? true : false
                                    }}
                                />
                                <TextField
                                    label="CPF"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("cpf")}
                                    error={!!errors.cpf}
                                    value={mascaraCPF(watch("cpf"))}
                                    helperText={<>{errors.cpf?.message}</>}
                                    InputLabelProps={{
                                        shrink: watch('cpf')?.length ? true : false
                                    }}
                                />
                                <TextField
                                    label="CNPJ"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("cnpj")}
                                    error={!!errors.cnpj}
                                    value={mascaraCNPJ(watch("cnpj"))}
                                    helperText={<>{errors.cnpj?.message}</>}
                                    InputLabelProps={{
                                        shrink: watch('cnpj')?.length ? true : false
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </FormContainer>
                    <ResumoPaper>
                        <Typography variant="h6">Resumo</Typography>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome</b>: {watch("nome")?.length ? watch("nome") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>E-mail</b>: {watch("email")?.length ? watch("email") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Telefone</b>: {watch("telefone")?.length ? watch("telefone") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>CPF</b>: {watch("cpf")?.length ? watch("cpf") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>CNPJ</b>: {watch("cnpj")?.length ? watch("cnpj") : "-"}
                            </Typography>
                        </Paper>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/entregadores")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    {uuid ? "Editar" : "Cadastrar"}
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}