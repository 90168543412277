import { useMemo, useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory"
import { useSnackbar } from "../../core/contexts/SnackbarContext";

export const useWhatsApp = () => {
    const [loading, setLoading] = useState(false);
    const notificar = useSnackbar();
    const apiMarketplaces = useMemo(() => ApiFactory.getApi("MS_MARKETPLACES"), []);

    const gerarQRCode = async (marketplaceUuid: string) => {
        setLoading(true);
        try {
            if(!marketplaceUuid) {
                return notificar("Marketplace inválido", { severity: 'error' })
            }

            const { data } = await apiMarketplaces.get(`/whatsapp/${marketplaceUuid}/qr-code`);

            return data?.qrCode;
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const gerarCodigo = async (marketplaceUuid: string, telefone: string) => {
        setLoading(true);
        try {
            if(!marketplaceUuid) {
                return notificar("Marketplace inválido", { severity: 'error' })
            }

            if(!telefone) {
                return notificar("Telefone inválido", { severity: 'error' })
            }

            const { data } = await apiMarketplaces.get(`/whatsapp/${marketplaceUuid}/codigo/${telefone}`);

            if(!data?.codigo?.length) {
                
            }

            return data?.codigo;
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }


    const consultarStatus = async (marketplaceUuid: string) => {
        try {
            if(!marketplaceUuid) {
                return notificar("Marketplace inválido", { severity: 'error' })
            }
            const { data } = await apiMarketplaces.get(`/whatsapp/${marketplaceUuid}/status`);
            return data?.conectado;
        } catch (error) {
            return notificar("Não foi possível consultar o status", { severity: "error" });
        }
    }

    return { gerarQRCode, gerarCodigo, consultarStatus, loading }
}