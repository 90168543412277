import { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Box
} from "@mui/material";
import { Tools, ToolsContainer, ToolsTitle } from "../../styles";
import { StatusPedidoDescricao } from "../../../../../../enum/StatusPedido";
import { IMarketplace } from "../../../../../../../marketplace/interfaces/IMarketplace";
import { useEntregadores } from "../../../../../../../entregadores/hooks/useEntregadores";
import { IEntregador } from "../../../../../../../entregadores/interfaces/IEntregador";
import { useNavigate } from "react-router-dom";
import { LoadingContainer, SemEntregadorContainer } from "./styles";

interface ToolsEmPreparoDeliveryProps {
    quantidadePedidosSelecionados: number;
    loading: boolean;
    atualizarStatus: (status: StatusPedidoDescricao, entregador?: any) => void;
    abrirCancelamento: () => void;
    marketplace: IMarketplace;
}

export const ToolsEmPreparoDelivery = ({
    atualizarStatus,
    loading,
    quantidadePedidosSelecionados,
    abrirCancelamento,
    marketplace
}: ToolsEmPreparoDeliveryProps) => {
    const { listar, loading: loadingEntregadores } = useEntregadores();

    const [openDialog, setOpenDialog] = useState(false);
    const [entregadores, setEntregadores] = useState<IEntregador[]>([]);
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<string>("");

    const navigate = useNavigate();

    const handleDespacharPedido = () => {
        setOpenDialog(true);
    };

    const handleConfirmarDespacho = () => {
        const entregador = entregadores.find(e => e.uuid === entregadorSelecionado);
        atualizarStatus(StatusPedidoDescricao["Em transporte"], entregador);
        setOpenDialog(false);
    };

    const handleBuscarEntregadores = async () => {
        const entregadores = await listar();
        setEntregadores(entregadores);
    }

    useEffect(() => {
        handleBuscarEntregadores();
    }, [])

    return (
        <Tools>
            <ToolsTitle>
                <Typography variant="subtitle1">
                    {quantidadePedidosSelecionados === 1 ? `1 pedido selecionado` : `${quantidadePedidosSelecionados} pedidos selecionados`}
                </Typography>
            </ToolsTitle>
            <ToolsContainer>
                {loading ? (
                    <CircularProgress size={15} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            disabled={quantidadePedidosSelecionados !== 1}
                            onClick={abrirCancelamento}
                        >
                            Cancelar pedido
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleDespacharPedido}
                        >
                            Despachar pedido(s)
                        </Button>
                    </>
                )}
            </ToolsContainer>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Selecionar entregador</DialogTitle>
                <DialogContent sx={{ minWidth: '400px' }}>
                    {loadingEntregadores ? (
                        <LoadingContainer>
                            <CircularProgress size={30} />
                        </LoadingContainer>
                    ) : entregadores.length ? (
                        <Select
                            fullWidth
                            value={entregadorSelecionado}
                            onChange={(event) => setEntregadorSelecionado(event.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Selecione um entregador</MenuItem>
                            {entregadores.map(entregador => (
                                <MenuItem key={entregador.uuid} value={entregador.uuid}>
                                    {entregador.nome} - {entregador.telefone}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <SemEntregadorContainer>
                            <Typography>
                                Nenhum entregador cadastrado
                            </Typography>
                            <Button onClick={() => navigate('/entregadores')}>Cadastrar entregador</Button>
                        </SemEntregadorContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={handleConfirmarDespacho} variant="contained">
                        {!entregadorSelecionado ? "Continuar sem entregador" : "Confirmar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Tools>
    );
};
