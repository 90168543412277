import { Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormLabel, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDataHora } from '../../../core/hooks/useDataHora';
import { useFetchPerfis } from '../../../perfis/hooks/useFetchPerfis';
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowDropDown, Block, Delete, Edit, Lock, LockOpen, SwitchAccount } from "@mui/icons-material";
import { ToolbarPaper, ToolbarBox, AcoesBox } from "./styles";
import { useFetchEntregadores } from "../../hooks/useFetchEntregadores";
import { IEntregador } from "../../interfaces/IEntregador";
import { filtrosEntregadoresSchema } from "../../schemas/filtrosEntregadoresSchema";
import { ConfirmarAcaoTextoModal } from "../../../core/components/ConfirmarAcaoTextoModal";
import { useEntregadores } from "../../hooks/useEntregadores";

export const ListarEntregadores = () => {
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [usuariosPorPagina, setUsuariosPorPagina] = useState(5);
    const { entregadores, isLoading, handleRefresh } = useFetchEntregadores();
    const { dataHoraBr } = useDataHora();
    const { estabelecimento, usuario: usuarioSessao } = useAutenticacaoContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [entregadoresSelecionados, setEntregadoresSelecionados] = useState<IEntregador[]>([]);

    const selecionarEntregador = (entregador: IEntregador) => {
        const selectedIndex = entregadoresSelecionados.findIndex(u => u.uuid === entregador.uuid);
        let newSelected: IEntregador[] = [];

        if (selectedIndex === -1) {
            newSelected = [...entregadoresSelecionados, entregador];
        } else {
            newSelected = [...entregadoresSelecionados.slice(0, selectedIndex), ...entregadoresSelecionados.slice(selectedIndex + 1)];
        }

        setEntregadoresSelecionados(newSelected);
    };

    const { excluir } = useEntregadores();
    const [openModalExclusao, setOpenModalExclusao] = useState(false);
    const [loadingExclusao, setLoadingExclusao] = useState(false);    
    const handleModalExclusao = () => setOpenModalExclusao(!openModalExclusao);
    const handleExcluirEntregador = async () => {
        setLoadingExclusao(true);
        await Promise.all(entregadoresSelecionados?.map(async entregadorSelecionado => {
            if(!entregadorSelecionado.uuid) return;
            await excluir(entregadorSelecionado.uuid);
        }))
        setLoadingExclusao(false);
        handleRefresh();
        handleModalExclusao();
        limparEntregadoresSelecionados();
        handleClose();
    }

    const limparEntregadoresSelecionados = () => setEntregadoresSelecionados([]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, novaPagina: number) => {
        setPaginaAtual(novaPagina);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsuariosPorPagina(parseInt(event.target.value, 10));
        setPaginaAtual(0);
    };

    const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(filtrosEntregadoresSchema),
        mode: "onChange",
    });

    const handleFiltrar = (values: FieldValues) => {
        setSearchParams(state => {
            if (values.nome) {
                state.set('nome', values.nome);
            } else {
                state.delete('nome');
            }
            if (values.email) {
                state.set('email', values.email);
            } else {
                state.delete('email');
            }
            if (values.telefone) {
                state.set('telefone', values.telefone);
            } else {
                state.delete('telefone');
            }
            return state;
        });
    }

    const handleLimpar = () => {
        setSearchParams(state => {
            state.delete('nome');
            state.delete('email');
            state.delete('telefone');
            return state;
        });

        setValue('nome', undefined)
        setValue('email', undefined)
        setValue('telefone', undefined)
        limparEntregadoresSelecionados();
    }

    const nomeFiltro = searchParams.get('nome') || '';
    const emailFiltro = searchParams.get('email') || '';
    const telefoneFiltro = searchParams.get('telefone') || '';

    useEffect(() => {
        if (nomeFiltro) setValue('nome', nomeFiltro);
        if (emailFiltro) setValue('email', emailFiltro);
        if (telefoneFiltro) setValue('telefone', telefoneFiltro);
    }, [nomeFiltro, emailFiltro, telefoneFiltro, setValue]);

    const entregadoresFiltrados = entregadores?.filter((entregador: IEntregador) => {
        const matchNome = nomeFiltro ? entregador.nome.toLowerCase().includes(nomeFiltro.toLowerCase()) : true;
        const matchEmail = emailFiltro ? entregador?.email?.toLowerCase().includes(emailFiltro.toLowerCase()) : true;
        const matchTelefone = telefoneFiltro ? entregador?.telefone?.toLowerCase().includes(telefoneFiltro.toLowerCase()) : true;
        return matchTelefone && matchNome && matchEmail;
    }) ?? [];

    const entregadoresExibidos: IEntregador[] = entregadoresFiltrados.slice(paginaAtual * usuariosPorPagina, (paginaAtual + 1) * usuariosPorPagina);

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Listagem de entregadores")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <Box>
            <ToolbarPaper>
                <Typography variant="h6" fontWeight={"bold"}>
                    {isLoading 
                        ? "Carregando entregadores..." 
                        : entregadoresExibidos?.length == 0
                            ? "Nenhum entregador cadastrado"
                            : `${entregadoresExibidos.length} Entregadores`
                    }
                </Typography>
                <form onSubmit={handleSubmit(handleFiltrar)}>
                    <ToolbarBox>
                        <FormControl className="input-toolbar"  sx={{ mr: 2 }}>
                            <TextField
                                id="nome"
                                label="Nome"
                                type="text"
                                fullWidth
                                {...register("nome")}
                                error={!!errors.nome}
                                helperText={<>{errors.nome?.message}</>}
                                size="small"
                            />
                        </FormControl>
                        <FormControl className="input-toolbar" sx={{ mr: 2 }}>
                            <TextField
                                id="telefone"
                                label="Telefone"
                                type="text"
                                fullWidth
                                {...register("telefone")}
                                error={!!errors.telefone}
                                helperText={<>{errors.telefone?.message}</>}
                                size="small"
                            />
                        </FormControl>
                        <FormControl className="input-toolbar">
                            <TextField
                                id="email"
                                label="E-mail"
                                type="text"
                                fullWidth
                                {...register("email")}
                                error={!!errors.email}
                                helperText={<>{errors.email?.message}</>}
                                size="small"
                            />
                        </FormControl>
                        <Box sx={{ ml: "1rem" }}>
                            <Button
                                variant="outlined"
                                sx={{ marginRight: "10px" }}
                                onClick={() => handleLimpar()}
                            >
                                Limpar
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Filtrar
                            </Button>
                        </Box>
                        <Divider sx={{ ml: 2, mr: 2 }} orientation="vertical" flexItem />
                        <AcoesBox>
                            <Button
                                onClick={handleClick}
                                endIcon={<ArrowDropDown />}
                                variant={"contained"}
                                disabled={entregadoresSelecionados?.length <= 0}
                            >
                                Ações
                            </Button>
                            <Menu
                                id={`usuarios-menu`}
                                MenuListProps={{
                                    'aria-labelledby': `usuarios-menu`,
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem disabled>
                                    <Typography variant="inherit" noWrap>
                                        {entregadoresSelecionados?.length} {entregadoresSelecionados?.length == 1 ? "entregador" : "entregadores"} {entregadoresSelecionados?.length == 1 ? "selecionado" : "selecionados"}
                                    </Typography>
                                </MenuItem>
                                <Divider />
                                <Tooltip title='Edite o entregador'>
                                    <MenuItem disabled={entregadoresSelecionados?.length != 1} onClick={() => navigate(`/entregadores/editar/${entregadoresSelecionados[0]?.uuid}`)}>
                                        <ListItemIcon color="inherit">
                                            <Edit fontSize={"small"} />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Editar
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title='Exclua o entregador'>
                                    <MenuItem 
                                        disabled={entregadoresSelecionados?.length > 25} 
                                        onClick={handleModalExclusao}
                                    >
                                        <ListItemIcon color="inherit">
                                            <Delete fontSize={"small"} />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Excluir
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                            </Menu>
                        </AcoesBox>
                    </ToolbarBox>
                </form>
            </ToolbarPaper>
            <Box sx={{ maxWidth: "90%", margin: "0 auto" }}>
                <TableContainer sx={{ width: "100%", minHeight: 500 }} component={Paper}>
                    {isLoading ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: "500px"
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Telefone</TableCell>
                                    <TableCell>CPF</TableCell>
                                    <TableCell>CNPJ</TableCell>
                                    <TableCell>Criado às</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {entregadores && entregadoresExibidos && estabelecimento && entregadoresExibidos.map(entregador => {
                                    const usuarioEstaSelecionado = entregadoresSelecionados.some(u => u.uuid === entregador.uuid);

                                    return (
                                        <TableRow
                                            key={entregador.uuid}
                                            selected={usuarioEstaSelecionado}
                                            aria-checked={usuarioEstaSelecionado}
                                            role="checkbox"
                                            hover
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={usuarioEstaSelecionado}
                                                    onClick={() => selecionarEntregador(entregador)}
                                                    disabled={entregador.uuid == usuarioSessao?.uuid}
                                                />
                                            </TableCell>
                                            <TableCell>{entregador.nome}</TableCell>
                                            <TableCell>{entregador.email ?? "-"}</TableCell>
                                            <TableCell>{entregador.telefone ?? "-"}</TableCell>
                                            <TableCell>{entregador.cpf ?? "-"}</TableCell>
                                            <TableCell>{entregador.cnpj ?? "-"}</TableCell>
                                            <TableCell>{dataHoraBr(entregador.cadastradoAs)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                    <TablePagination
                        component="div"
                        count={entregadores?.length ?? 0}
                        rowsPerPage={usuariosPorPagina}
                        page={paginaAtual}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />
                </TableContainer>
            </Box>
            <ConfirmarAcaoTextoModal
                cancelar={handleModalExclusao}
                confirmar={handleExcluirEntregador}
                mensagem={
                    entregadoresSelecionados?.length === 1
                    ? <Typography>Digite <b>Excluir</b> no campo abaixo para remover o entregador.</Typography>
                    : <Typography>Digite <b>Excluir</b> no campo abaixo para remover os {entregadoresSelecionados?.length} entregadores.</Typography>
                }
                open={openModalExclusao}
                parametro="Excluir"
                titulo={
                    entregadoresSelecionados?.length == 1
                    ? `Excluir entregador`
                    : `Excluir entregadores` 
                }
                loading={loadingExclusao}
            />
        </Box>
    );
}