import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, Autocomplete, Select, MenuItem, FormControl, InputLabel, Link } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { RealInput } from "../../../core/components/RealInput";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { MetodoPagamento } from "../../../core/enum/MetodoPagamento";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useConfiguracoesPedidoContext } from "../../contexts/ConfiguracoesPedidoContext";
import { IPagamentoPedido } from "../../interface/IPagamentoPedido";
import { MetodoPagamentoItem } from "./styles";

interface MetodoPagamentoSelectProps {
    pagamento: IPagamentoPedido | undefined;
    onChange?: (pagamentos?: IPagamentoPedido) => void;
    valorTotal?: number;
    size?: "small" | "medium"
    disabled?: boolean;
}

export const MetodoPagamentoSelect = ({ onChange, pagamento, valorTotal, size, disabled }: MetodoPagamentoSelectProps) => {
    const [openTroco, setOpenTroco] = useState(false);
    const { configuracao } = useConfiguracoesPedidoContext();
    const { real } = useMoeda();
    const snackbar = useSnackbar();

    const [valorRecebido, setValorRecebido] = useState<undefined | number>();
    const valorTroco = useMemo(() => valorRecebido ? (valorRecebido - (valorTotal ?? 0)) : 0, [valorTotal, valorRecebido, pagamento])

    const handleClose = () => {
        setOpenTroco(false);
        setValorRecebido(undefined);
    }

    useEffect(() => {
        if (pagamento) {
            setValorRecebido(pagamento.valorRecebido);
        }
    }, [pagamento])

    const handleOpen = () => setOpenTroco(true);

    const handleMetodoPagamento = (metodoPagamento: string | null) => {

        if (metodoPagamento == MetodoPagamento.Dinheiro) {
            handleOpen();
        }

        if (onChange) {
            return onChange({
                metodoPagamento: metodoPagamento ?? undefined,
                valorRecebido,
                valorTroco,
                valor: valorTotal
            });
        }
    }

    const handleMetodoPagamentoDinheiro = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (valorTroco && valorTroco < 0) {
            return snackbar("Troco inválido", { severity: "error" });
        }

        if (onChange) {
            onChange({
                metodoPagamento: MetodoPagamento.Dinheiro,
                valorRecebido,
                valorTroco,
                valor: valorTotal
            });
        }
        handleClose();
    }

    const handleValorRecebido = (e: ChangeEvent<HTMLInputElement>) => {
        setValorRecebido(Math.ceil(parseFloat(e.target.value)));
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Dialog open={openTroco} onClose={handleClose}>
                <form onSubmit={handleMetodoPagamentoDinheiro}>
                    <DialogTitle>Calcular troco</DialogTitle>
                    <DialogContent sx={{ width: 400 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Valor recebido"
                            type="text"
                            fullWidth
                            onChange={handleValorRecebido}
                            value={valorRecebido}
                            InputProps={{
                                inputComponent: RealInput as any,
                            }}
                        />
                        <Alert severity="info" sx={{ marginTop: "2rem" }}>
                            <AlertTitle>
                                {real(valorTotal)}
                            </AlertTitle>
                            Valor total
                        </Alert>
                        {valorTroco && valorTroco < 0 ? (
                            <Alert severity="error" sx={{ marginTop: "2rem" }}>
                                <AlertTitle>
                                    {real(valorTroco)}
                                </AlertTitle>
                                Troco inválido
                            </Alert>
                        ) : (
                            <Alert severity="success" sx={{ marginTop: "2rem" }}>
                                <AlertTitle>
                                    {real(valorTroco)}
                                </AlertTitle>
                                Troco válido
                            </Alert>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={handleClose}>Cancelar</Button>
                        <Button type="submit">Salvar</Button>
                    </DialogActions>
                </form>
            </Dialog>
            {configuracao?.metodosPagamento && configuracao.metodosPagamento.length > 0 ? (
                configuracao.metodosPagamento.length <= 4 ? configuracao.metodosPagamento.map((metodo) => (
                    <MetodoPagamentoItem
                        key={metodo}
                        variant="outlined"
                        sx={{ marginTop: "0.3rem" }}
                        selecionado={metodo === pagamento?.metodoPagamento}
                        onClick={() => handleMetodoPagamento(metodo)}
                    >
                        {metodo}
                    </MetodoPagamentoItem>
                )) : (
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            value={pagamento?.metodoPagamento || ""}
                            onChange={(event, newValue) => {
                                handleMetodoPagamento(newValue);
                            }}
                            disabled={disabled}
                            options={configuracao?.metodosPagamento || []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Método de Pagamento"
                                    variant="outlined"
                                    size={size}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </FormControl>
                )
            ) : (
                <Alert severity="info">
                    Nenhum método de pagamento configurado. <br />
                    <Link href="/pedidos/configuracoes/metodos-pagamento">Clique aqui</Link> para configurar.
                </Alert>
            )}
            {pagamento?.metodoPagamento === MetodoPagamento.Dinheiro && (
                <>
                    {valorTroco && valorTroco < 0 ? (
                        <Alert severity="error" sx={{ marginTop: "0.6rem" }}>
                            <AlertTitle>
                                {real(valorTroco)}
                            </AlertTitle>
                            Troco inválido
                        </Alert>
                    ) : (
                        <Alert severity="success" sx={{ marginTop: "0.6rem" }}>
                            <AlertTitle>
                                {real(valorTroco)}
                            </AlertTitle>
                            Troco válido
                        </Alert>
                    )}
                </>
            )}
        </Box>
    );
}
