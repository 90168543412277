import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const IntegracaoContainer = styled(Paper)`
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
`

export const LoadingContainer = styled(Box)`
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NenhumaInterrupcaoContainer = styled(Box)`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`