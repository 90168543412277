export const useLetras = () => {
    const minusculas = (texto: string, numeroMaximo?: number) => {
        if(!numeroMaximo || (texto.length <= numeroMaximo)) {
            return texto.toLowerCase();
        }

        return texto.slice(0, numeroMaximo).toLowerCase() + "..."
    }

    const maiusculas = (texto: string, numeroMaximo?: number) => {
        if(!numeroMaximo || (texto.length <= numeroMaximo)) {
            return texto.toUpperCase();
        }

        return texto.slice(0, numeroMaximo).toUpperCase() + "..."
    }

    const removerAcentos = (str: string) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
    }

    const removerCedilha = (str: string) => {
        return str
            .normalize("NFD")
            .replace(/ç/g, "c");
    }

    const titulo = (texto: string, numeroMaximo?: number, iniciarCadaPalavraMaiuscula?: boolean) => {
        if (!texto || texto.length === 0) {
            return "";
        }

        if (numeroMaximo && texto.length > numeroMaximo) {
            texto = texto.slice(0, numeroMaximo) + "...";
        }

        if (iniciarCadaPalavraMaiuscula) {
            return texto
                .toLowerCase()
                .split(' ')
                .map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1))
                .join(' ');
        } else {
            return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }
    };
    
    return {
        maiusculas,
        minusculas,
        titulo,
        removerAcentos,
        removerCedilha
    }
}