import { Box, CircularProgress } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IMarketplace } from "../../interfaces/IMarketplace";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { LoadingContainer } from "./styles";
import { TipoMarketplace } from "../../enum/TipoMarketplace";
import { useFetchMarketplacesVinculo } from "../../hooks/useFetchMarketplacesVinculo";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { CardapioOnlineContainer } from "./container/CardapioOnlineContainer";
import { IFoodContainer } from "./container/IFoodContainer";
import { WhatsAppContainer } from "./container/WhatsAppContainer";

export const DetalhesMarketplace = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { marketplaces, loading, handleRefresh } = useFetchMarketplacesVinculo();
    const [marketplace, setMarketplace] = useState<IMarketplace | undefined>(undefined);
    const { uuid } = useParams();
    useEffect(() => {
        if (uuid && loading == false) {
            const marketplaceEncontrado = marketplaces?.find(marketplaceProcurado => marketplaceProcurado.uuid === uuid);
            if (!marketplaceEncontrado) {
                navigate("/marketplace");
                return snackbar("Marketplace não encontrado", { severity: "error" })
            }
            setMarketplace(marketplaceEncontrado);
        }
    }, [uuid, marketplaces, loading])


    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Gestão de marketplace")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    const renderizarMarketplace = () => {
        if(marketplace?.tipo == TipoMarketplace.CARDAPIO_DIGITAL_DELIVERY_RETIRADA) {
            return <CardapioOnlineContainer 
                handleRefresh={handleRefresh}
                loadingFetch={loading}
                key={marketplace?.uuid}
                marketplace={marketplace}
            />
        }

        if(marketplace?.tipo == TipoMarketplace.IFOOD) {
            return <IFoodContainer 
                handleRefresh={handleRefresh}
                loadingFetch={loading}
                key={marketplace?.uuid}
                marketplace={marketplace}
            />
        }

        if(marketplace?.tipo == TipoMarketplace.WHATSAPP) {
            return <WhatsAppContainer 
                handleRefresh={handleRefresh}
                loadingFetch={loading}
                key={marketplace?.uuid}
                marketplace={marketplace}
                marketplaces={marketplaces}
            />
        }

        return <Box>Marketplace não implementado</Box>
    }

    return (
        <>
            {loading ? (
                <LoadingContainer>
                    <CircularProgress size={60}/>
                </LoadingContainer>
            ) : renderizarMarketplace()}
        </>
    );
}