import { Add, ArrowDropDown, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogContent, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, OutlinedInput, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { validarMoedaReal } from "../../../../../core/utils/validarMoedaReal";
import { IITemComplemento } from "../../../../interfaces/IITemComplemento";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { FichaTecnica } from "../../../FichaTecnica";
import { ButtonsContainer, FiltroContainer, ItemCard, ItemImage, ItensPaper } from "./styles";
import { TipoControleEstoque } from "../../../../enum/TipoControleEstoque";
import { useCadastrarEditarProdutoContext } from "../../../../contexts/CadastrarEditarProdutoContext";
import { IImagemProduto } from "../../../../interfaces/IImagemProduto";
import { ImagemContainer } from "../../../ConfiguracaoPreparadoContainer/styles";
import { CardUploadImagem } from "../../../../../core/components/CardUploadImagem";
import { MIMETypes } from "../../../../../core/enum/MIMETypes";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { useTamanhoTela } from "../../../../../core/hooks/useTamanhoTela";
import { ModalAtualizaPreco } from "../../../ModalAtualizarPreco";
import { ConfirmacaoAcaoModal } from "../../../../../core/components/ConfirmacaoAcaoModal";

interface ItensComplementosContainerProps {
    itens?: IITemComplemento[];
    salvar: (item: IITemComplemento) => void;
    excluir: (uuid: string) => void;
}



export const ItensComplementosContainer = ({ salvar, itens, excluir }: ItensComplementosContainerProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [atualizacaoPreco, setAtualizacaoPreco] = useState<boolean>(false);

    const handleAbrirAtualizacaoPreco = () => {
        setAtualizacaoPreco(true);
        handleClose();
    }

    const handleFecharAtualizacaoPreco = () => {
        setAtualizacaoPreco(false);
    }

    const handleAbrirExclusao = async () => {
        setConfirmacaoExclusao(true);
        handleClose();
    }

    const handleFecharExclusao = async () => {
        setConfirmacaoExclusao(false);
    }

    const [fichaTecnica, setFichaTecnica] = useState<IInsumo[]>([]);
    const [descricao, setDescricao] = useState<string | undefined>('');
    const [imagem, setImagem] = useState<undefined | File | IImagemProduto>(undefined);
    const [detalhes, setDetalhes] = useState<string | undefined>('');
    const [preco, setPreco] = useState<string | undefined>('');
    const [erroDescricao, setErroDescricao] = useState<undefined | string>(undefined);
    const [erroDetalhes, setErroDetalhes] = useState<undefined | string>(undefined);
    const [erroPreco, setErroPreco] = useState<undefined | string>(undefined);
    const [itemEdicao, setItemEdicao] = useState<undefined | IITemComplemento>(undefined);
    const { controleEstoque } = useCadastrarEditarProdutoContext();
    const [formularioAberto, setFormularioAberto] = useState<boolean>(false);
    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => {
        setDescricao('');
        setPreco('');
        setDetalhes('');
        setFormularioAberto(false);
        setErroDescricao(undefined);
        setErroPreco(undefined);
        setErroDetalhes(undefined);
        setItemEdicao(undefined);
        setImagem(undefined);
    }

    const { number, real } = useMoeda();

    const editar = (item: IITemComplemento) => {
        handleClose();
        setItemEdicao(item);
        abrirFormulario();
    }

    const handleDescricao = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescricao(e.target.value)

        if (!e.target.value) {
            setErroDescricao("Descrição inválida");
        } else {
            if (e.target.value.length >= 100) {
                setErroDescricao("O limite de caracteres é 100");
            } else {
                setErroDescricao(undefined);
            }
        }
    }

    const handleDetalhes = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDetalhes(e.target.value)

        if (e.target.value.length >= 240) {
            setErroDetalhes("O limite de caracteres é 250");
        }
    }

    const handlePreco = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPreco(e.target.value);

        const validacaoPreco = validarMoedaReal(e.target.value, 0.00, undefined, true);
        if (e.target.value?.length) {
            setErroPreco(validacaoPreco);
        } else {
            setErroPreco(undefined);
        }
    }

    const handleInativar = (item: IITemComplemento) => {
        salvar({
            descricao: item.descricao,
            fichaTecnica: item.fichaTecnica,
            preco: item.preco,
            detalhes: item.detalhes,
            quantidade: item.quantidade,
            uuid: item.uuid,
            imagens: item.imagens,
            inativadoAs: item.inativadoAs ? null : new Date()
        });
    }

    const handleSalvar = () => {
        let erro = false;

        if (!descricao) {
            setErroDescricao("A descrição é obrigatória");
            erro = true;
        }

        const validacaoPreco = validarMoedaReal(preco, 0.00, undefined, true);
        if (!preco || validacaoPreco) {
            setErroPreco(validacaoPreco || "O preço é obrigatório");
            erro = true;
        }

        if (!erro && descricao) {
            const imagens: IImagemProduto[] = [];
            if (imagem) {
                imagens.push(imagem as IImagemProduto);
            }
            salvar({
                descricao,
                fichaTecnica,
                preco: number(preco),
                detalhes,
                uuid: itemEdicao?.uuid ?? v4(),
                quantidade: itemEdicao?.quantidade,
                inativadoAs: itemEdicao?.inativadoAs,
                imagens
            });

            fecharFormulario();
            limparItensSelecionados();
        }
    }

    const adicionarInsumo = (insumo: IInsumo) => {
        setFichaTecnica(ficha => [...ficha, insumo]);
    }

    const excluirInsumo = (uuid: string) => {
        setFichaTecnica(ficha => ficha.filter(insumo => insumo.uuid !== uuid));
    }

    const mimetypesValidos = [
        MIMETypes["image/jpeg"],
        MIMETypes["image/jpg"],
        MIMETypes["image/png"]
    ] as string[];

    const snackbar = useSnackbar();

    const handleUploadImagem = (arquivo: File) => {
        if (!mimetypesValidos.includes(arquivo.type)) {
            return snackbar(`Arquivo inválido, tipos aceitos: (PNG, JPEG)`, { severity: "error" })
        }

        setImagem(arquivo);
    }

    const handleExcluirImagem = () => {
        setImagem(undefined);
    }

    useEffect(() => {
        if (itemEdicao) {
            setDescricao(itemEdicao.descricao);
            setDetalhes(itemEdicao.detalhes ?? '');
            setPreco(String(itemEdicao.preco));
            setFichaTecnica(itemEdicao.fichaTecnica ?? []);
            if (itemEdicao.imagens?.length) {
                setImagem(itemEdicao.imagens[0]);
            }
        }
    }, [itemEdicao])

    const { titulo } = useLetras();
    const { modeloTela } = useTamanhoTela();
    const [filtroItens, setFiltroItens] = useState("");
    const [itensSelecionados, setItensSelecionados] = useState<string[]>([]);

    const limparItensSelecionados = () => {
        setItensSelecionados([]);
    }
    const toggleSelecionado = (uuid: string) => {
        setItensSelecionados(prev =>
            prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
        );
    };

    const toggleSelecionarTodos = () => {
        if (itensSelecionados.length === itens?.length) {
            limparItensSelecionados();
        } else {
            setItensSelecionados(itens?.length ? itens?.map(item => item.uuid) : []);
        }
    };

    const handleAtualizarPreco = (preco: string) => {
        itensSelecionados?.map(itemUuid => {
            const item = itens?.find(item => item.uuid == itemUuid);
            if (item) {
                salvar({
                    ...item,
                    preco: number(preco)
                });
            }
        })

        handleFecharAtualizacaoPreco();
        limparItensSelecionados();
    }

    const handleExcluirItens = async () => {
        itensSelecionados?.map(itemUuid => {
            const item = itens?.find(item => item.uuid == itemUuid);
            if (item) {
                excluir(item?.uuid);
            }
        })
        handleFecharExclusao();
        limparItensSelecionados();
    }

    const renderizarLinha = (item: IITemComplemento) => (
        <TableRow>
            <TableCell align="center">
                <Checkbox
                    checked={itensSelecionados.includes(item.uuid)}
                    onChange={() => toggleSelecionado(item.uuid)}
                />
            </TableCell>
            <TableCell align="center">
                <img alt={item.descricao} src={
                    item?.imagens?.length
                        ? item?.imagens[0] instanceof File ? URL.createObjectURL(item?.imagens[0]) : item?.imagens[0].url
                        : `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                } style={{
                    width: "60px",
                    height: "50px",
                    borderRadius: "5px"
                }} />
            </TableCell>
            <TableCell align="center">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'left'
                }}>
                    <Typography fontSize={14}>
                        {item.descricao}
                    </Typography>
                    {item.detalhes && (
                        <Typography color={"GrayText"} fontSize={10}>
                            {item.detalhes?.length > 35 ? `${item.detalhes?.slice(0, 35)}...` : item.detalhes}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            <TableCell align="center">
                <Typography fontSize={13}>
                    {real(item.preco)}
                </Typography>
            </TableCell>
            <TableCell>
                <Switch
                    size="small"
                    checked={item.inativadoAs == undefined}
                    onClick={() => handleInativar(item)}
                />
            </TableCell>
        </TableRow>
    );

    return (
        <ItensPaper variant="outlined">
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                <Typography variant="h6">
                    Itens
                </Typography>
                <FiltroContainer>
                    {modeloTela == "desktop" && (
                        <TextField
                            placeholder='Nome'
                            size='small'
                            value={filtroItens}
                            onChange={(e) => setFiltroItens(e.target.value)}
                            sx={{ width: "200px" }}
                        />
                    )}
                    <Box>
                        <Button
                            onClick={handleClick}
                            endIcon={<ArrowDropDown />}
                            variant="contained"
                            disabled={itensSelecionados.length <= 0}
                        >
                            Ações
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem disabled>
                                <Typography variant="inherit" noWrap>
                                    {itensSelecionados.length} {itensSelecionados.length === 1 ? "item selecionado" : "itens selecionados"}
                                </Typography>
                            </MenuItem>
                            <Divider />
                            <Tooltip title={itensSelecionados?.length <= 1 ? 'Editar' : 'Alterar preço'}>
                                <MenuItem disabled={itensSelecionados?.length == 0} onClick={() => {
                                    if (itensSelecionados?.length == 1) {
                                        const item = itens?.find(item => item.uuid == itensSelecionados[0])
                                        if (item) {
                                            return editar(item);
                                        }
                                    }
                                    handleAbrirAtualizacaoPreco();
                                }}>
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        {itensSelecionados?.length <= 1 ? 'Editar' : 'Alterar preço'}
                                    </Typography>
                                </MenuItem>
                            </Tooltip>
                            <Tooltip title={itensSelecionados?.length == 1 ? `Excluir item` : `Excluir itens`}>
                                <MenuItem
                                    onClick={handleAbrirExclusao}
                                    disabled={itensSelecionados?.length == 0}
                                >
                                    <ListItemIcon>
                                        <Delete fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        Excluir
                                    </Typography>
                                </MenuItem>
                            </Tooltip>
                        </Menu>
                    </Box>
                    <Tooltip title='Crie um novo item'>
                        <IconButton color="primary" onClick={abrirFormulario}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </FiltroContainer>
            </Toolbar>
            {itens?.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '50px' }} align="center">
                                <Checkbox
                                    checked={itensSelecionados.length === itens.length && itens.length > 0}
                                    indeterminate={itensSelecionados.length > 0 && itensSelecionados.length < itens.length}
                                    onChange={toggleSelecionarTodos}
                                />

                            </TableCell>
                            <TableCell align="center">Imagem</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Preço</TableCell>
                            <TableCell align="center">Ativo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtroItens
                            ? itens.filter(item => item.descricao.toLowerCase().includes(filtroItens.toLocaleLowerCase()))?.map(item => {
                                return renderizarLinha(item);
                            })
                            : itens?.map(item => {
                                return renderizarLinha(item);
                            })}

                    </TableBody>
                </Table>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ width: '50px' }}>
                                <Checkbox />
                            </TableCell>
                            <TableCell align="center">Imagem</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Preço</TableCell>
                            <TableCell align="center">Ativo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" />
                            <TableCell align="center" />
                            <TableCell align="center">Nenhum item cadastrado</TableCell>                            
                            <TableCell align="center" />
                            <TableCell align="center" />
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            <Dialog open={formularioAberto} onClose={fecharFormulario} maxWidth="md">
                <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "500px", maxHeight: "80vh", overflowY: "auto" }}>
                    <Typography
                        variant="h6"
                    >
                        {itemEdicao?.uuid ? "Editar" : "Cadastrar"} item
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                            type="text"
                            placeholder="Ex: Bacon, Carne extra, etc..."
                            value={descricao}
                            onChange={handleDescricao}
                            label="Descrição"
                            error={erroDescricao ? true : false}
                            helperText={<>{erroDescricao}</>}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                            type="text"
                            placeholder="Detalhes"
                            value={detalhes}
                            onChange={handleDetalhes}
                            label="Detalhes"
                            error={erroDetalhes ? true : false}
                            helperText={<>{erroDetalhes}</>}
                            multiline
                            rows={3}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel htmlFor="preco-item-complemento" error={erroPreco ? true : false}>Preço</InputLabel>
                        <OutlinedInput
                            id="preco-item-complemento"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Preço"
                            onChange={handlePreco}
                            value={preco}
                            error={erroPreco ? true : false}
                        />
                        {erroPreco && (<FormHelperText error={true}>{erroPreco}</FormHelperText>)}
                    </FormControl>
                    {controleEstoque == TipoControleEstoque.completo && <FichaTecnica adicionarInsumo={adicionarInsumo} fichaTecnica={fichaTecnica} excluirInsumo={excluirInsumo} />}
                    <ImagemContainer>
                        <CardUploadImagem
                            titulo="Imagem"
                            descricao={
                                imagem
                                    ? (imagem instanceof File ? imagem?.name : (imagem?.nomeOriginal ?? imagem?.nome))
                                    : "Faça o upload"
                            }
                            url={imagem instanceof File ? URL.createObjectURL(imagem) : imagem?.url}
                            excluir={handleExcluirImagem}
                            upload={handleUploadImagem}
                        />
                    </ImagemContainer>
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={fecharFormulario}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSalvar}
                        >
                            Salvar
                        </Button>
                    </ButtonsContainer>
                </DialogContent>
            </Dialog>
            <ConfirmacaoAcaoModal
                open={confirmacaoExclusao}
                descricao={itensSelecionados?.length == 1
                    ? `Você deseja excluir o item selecionado?`
                    : `Você deseja excluir os ${itensSelecionados?.length} itens selecionados?`
                }
                titulo={itensSelecionados?.length == 1 ? `Excluir item` : `Excluir itens`}
                onCancelar={handleFecharExclusao}
                onConfirmar={handleExcluirItens}
            />
            <ModalAtualizaPreco
                aberto={atualizacaoPreco}
                fechar={handleFecharAtualizacaoPreco}
                atualizar={handleAtualizarPreco}
            />
        </ItensPaper>
    );
}