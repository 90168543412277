import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { HeaderContextProvider } from "../../core/contexts/HeaderContext";
import { IEstabelecimento } from "../../estabelecimento/interfaces/IEstabelecimento";
import { Layout } from "../../core/components/Layout";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { IMenu } from "../../core/interfaces/IMenu";
import { useMemo } from "react";
import { ListarEntregadores } from "../pages/ListarEntregadores";
import { CadastrarEditarEntregador } from "../pages/CadastrarEditarEntregador";

interface IEntregadoresRoutesProps {
    autenticado: boolean;
}

export const EntregadoresRoutes = ({ autenticado }: IEntregadoresRoutesProps) => {
    const { modeloTela } = useTamanhoTela();

    const { perfil } = useAutenticacaoContext();

    const itensMenu: IMenu[] = useMemo(() => {
        const itens = [
            {
                titulo: "Base de entregadores",
                url: "/entregadores",
                subitens: []
            },
            {
                titulo: "Cadastrar",
                url: "/entregadores/cadastrar",
                subitens: []
            }
        ];

        return itens
    }, [perfil])

    return (
        <HeaderContextProvider>
            <Routes>
                <Route element={
                    <Layout
                        titulo="Entregadores"
                        itensMenu={itensMenu}
                        modeloTela={modeloTela}
                        drawer
                    >
                        <PrivateRoutes
                            autenticado={autenticado}
                        />
                    </Layout>
                }>
                    <Route path="/" element={<ListarEntregadores />} />
                    <Route path="/cadastrar" element={<CadastrarEditarEntregador />} />
                    <Route path="/editar/:uuid" element={<CadastrarEditarEntregador />} />
                    <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
                </Route>
            </Routes>
        </HeaderContextProvider >
    );
}