import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { Layout } from "../../core/components/Layout";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { useMemo } from "react";
import { IMenu } from "../../core/interfaces/IMenu";
import { ConfiguracoesCardapioProvider } from "../../produtos/contexts/ConfiguracoesCardapioContext";
import { ConfiguracaoFluxoOperacao } from "../pages/ConfiguracaoFluxoOperacao";

interface IConfiguracoesProps {
  autenticado: boolean;
}

export const ConfiguracoesRoutes = ({ autenticado }: IConfiguracoesProps) => {
  const { modeloTela } = useTamanhoTela();
  const { perfil } = useAutenticacaoContext();

  const itensMenu: IMenu[] = useMemo(() => {
    const itens = [
      {
        titulo: "Fluxo de trabalho",
        url: "/configuracoes/fluxo/operacao",
      },
    ];

    return itens;
  }, [perfil]);

  return (
    <Routes>
      <Route
        element={
          <Layout
            titulo="Configurações"
            modeloTela={modeloTela}
            itensMenu={itensMenu}
            drawer
          >
            <PrivateRoutes autenticado={autenticado} />
          </Layout>
        }
      >
        <Route path="/" element={<Navigate to="/configuracoes/fluxo/operacao" />} />
        <Route
          path="/fluxo/operacao"
          element={
            <ConfiguracoesCardapioProvider>
              <ConfiguracaoFluxoOperacao />
            </ConfiguracoesCardapioProvider>
          }
        />
        <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
      </Route>
    </Routes>
  );
};
