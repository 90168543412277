import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { useFetch } from "../../core/hooks/useFetch";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IConfiguracaoCardapio } from "../interfaces/IConfiguracaoCardapio";
import { IFluxoOperacao } from "../../pedidos/interface/IFluxoOperacao";

interface IConfiguracoesCardapioContextProps {
    loading: boolean;
    configuracao: IConfiguracaoCardapio | undefined | null;
    salvarConfiguracoesComandas: (fluxoOperacao: IFluxoOperacao) => Promise<boolean>
}

export const useConfiguracoesCardapioContext = () => {
    return useContext(ConfiguracoesCardapioContext)
}
export const ConfiguracoesCardapioContext = createContext({} as IConfiguracoesCardapioContextProps)

export const ConfiguracoesCardapioProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const snackbar = useSnackbar();
    const apiConfiguracoes = useMemo(() => ApiFactory.getApi("MS_PEDIDOS"), []);
    const { data: configuracao, isLoading, handleRefresh } = useFetch<IConfiguracaoCardapio>(apiConfiguracoes, "/configuracoes");

    useEffect(() => setLoading(isLoading), [isLoading])

    const salvarConfiguracoesComandas = useCallback(async (fluxoOperacao: IFluxoOperacao) => {
        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.post(`/configuracoes/fluxo-operacao`, fluxoOperacao);
            if(data) {
                setLoading(false);
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao salvar as configurações de comandas`, { severity: "error" })
            return false;
        }

        return false;
    }, [])

    return (
        <ConfiguracoesCardapioContext.Provider value={{
            salvarConfiguracoesComandas,
            configuracao,
            loading,
        }}>
            {children}
        </ConfiguracoesCardapioContext.Provider>
    );
} 