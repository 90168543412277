import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, CircularProgress } from "@mui/material";
import { useIFood } from "../../../hooks/useIFood";
import { Close, Delete } from "@mui/icons-material";
import timeGridPlugin from "@fullcalendar/timegrid";
import { IHorariosFuncionamentoIFood, IHorarioFuncionamentoIFood } from "../../../interfaces/IHorariosFuncionamentoIFood";
import { EventSourceInput } from "@fullcalendar/core";
import { CalendarContainer } from "./styles";
import { TimePicker } from "@mui/x-date-pickers";

interface CardHorariosFuncionamentoIFoodProps {
    horarios: IHorariosFuncionamentoIFood;
    refresh: () => void;
    marketplaceUuid?: string;
}

const diasDaSemana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
interface ICardHorarioFuncionamentoIFood extends IHorarioFuncionamentoIFood {
    startTime: string;
    endTime: string;
}


export const CardHorariosFuncionamentoIFood = ({ horarios, marketplaceUuid, refresh }: CardHorariosFuncionamentoIFoodProps) => {
    const { salvarHorarios, loading } = useIFood();
    const [selectedEvent, setSelectedEvent] = useState<ICardHorarioFuncionamentoIFood | null>(null);

    const handleExcluirHorario = async () => {
        if (!marketplaceUuid || !selectedEvent) return;

        const novosHorarios = {
            ...horarios,
            shifts: horarios.shifts?.filter(h => h.id !== selectedEvent.id) || []
        };

        await salvarHorarios(marketplaceUuid, novosHorarios);
        setSelectedEvent(null);
        refresh();
    };


    const handleEventClick = (eventInfo: any) => {
        setSelectedEvent(eventInfo.event.extendedProps);
    };

    const diasDaSemanaMap: Record<string, number> = {
        "SUNDAY": 0,
        "MONDAY": 1,
        "TUESDAY": 2,
        "WEDNESDAY": 3,
        "THURSDAY": 4,
        "FRIDAY": 5,
        "SATURDAY": 6,
    };

    const diasDaSemanaTraducaoMap: Record<string, string> = {
        "SUNDAY": 'Sábado',
        "MONDAY": 'Segunda-feira',
        "TUESDAY": 'Terça-feira',
        "WEDNESDAY": 'Quarta-feira',
        "THURSDAY": 'Quinta-feira',
        "FRIDAY": 'Sexta-feira',
        "SATURDAY": 'Domingo'
    };

    const formatarEventos = (horarios: IHorariosFuncionamentoIFood) => {
        return horarios.shifts?.map(h => {
            const [startHour, startMinute] = h.start.split(":").map(Number);
            const endHour = (startHour + Math.floor((startMinute + h.duration) / 60)) % 24;
            const endMinute = (startMinute + h.duration) % 60;
    
            return {
                title: "Loja aberta",
                daysOfWeek: [diasDaSemanaMap[h.dayOfWeek]],
                startTime: `${startHour.toString().padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`,
                endTime: `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`,
                extendedProps: {
                    ...h,
                    startTime: `${startHour.toString().padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`,
                    endTime: `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`,
                }
            };
        }) || [];
    };
    

    return (
        <CalendarContainer>
            <FullCalendar
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                headerToolbar={false}
                allDaySlot={false}
                slotMinTime="00:00:00"
                slotMaxTime="23:59:59"
                locale={'pt-BR'}
                events={formatarEventos(horarios)}
                eventClick={handleEventClick}
                dayHeaderContent={({ date }) => diasDaSemana[date.getDay()]}
                eventClassNames="fc-event"
            />
            <Dialog open={selectedEvent != null} onClose={() => setSelectedEvent(null)}>
                <DialogTitle >Detalhes do Horário</DialogTitle>
                <IconButton
                edge="end"
                color="inherit"
                onClick={() => setSelectedEvent(null)}
                sx={{
                    position: 'absolute',
                    right: 20,
                    top: 8,
                }}
            >
                <Close />
            </IconButton>
                {selectedEvent && (
                    <DialogContent sx={{
                        minWidth: '350px'
                    }}>
                        <TextField
                            fullWidth
                            disabled
                            value={diasDaSemanaTraducaoMap[selectedEvent.dayOfWeek]}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <Box sx={{
                            display: 'flex',
                            gap: '1rem',
                            mt: '2rem'
                        }}>
                            <TextField
                                label="Início"
                                value={selectedEvent.startTime}
                                disabled
                            />
                            <TextField
                                label="Fim"
                                value={selectedEvent.endTime}
                                disabled
                            />
                        </Box>

                        <DialogActions sx={{ mt: 2 }}>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button variant="contained" color="error" startIcon={<Delete />} onClick={handleExcluirHorario}>
                                    Excluir
                                </Button>
                            )}

                        </DialogActions>
                    </DialogContent>
                )}
            </Dialog>
        </CalendarContainer>
    );
};